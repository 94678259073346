<template>
  <div class="containerKrpano">
    <div ref="fullScreenRef" class="fullScreen">
      <template v-if="showModal">
        <Modal>
          <template v-slot:header></template>
          <template v-slot:body>
            <div ref="modalBodyRef">
              <template v-if="showableCourseStructure">
                <HandleCourseStructure :courseStructure="showableCourseStructure" />
              </template>
              <button class="button raquoAfter" @click="close">Sulje</button>
            </div>
          </template>
        </Modal>
      </template>

      <div class="makeFullscreen">
        <div class="panorama" :id="panoramaId"></div>
      </div>
    </div>

    <br />
    <button @click="openFullscreen">Siirry koko ruudun tilaan</button>
  </div>
</template>


<script>
import { onMounted, ref, defineAsyncComponent } from "vue";

import Modal from "@tools/Modal";

import { useFullscreen } from "../task-types/useFullscreen";

import { CourseStructureHelper } from "@moods/taitaen-core";

import HandleCourseStructure from "../HandleCourseStructure";

import useContainer from "./useContainer";

export default {
  name: "PanoramaContainer",
  props: ["courseStructure", "data", "isTest"],
  components: {
    Modal,
    HandleCourseStructure: defineAsyncComponent(() => Promise.resolve(HandleCourseStructure)),
  },
  setup(props) {
    // Container startup
    const { getChildrenCourseStructures, initAnswers } = useContainer();
    const childrenCourseStructures = getChildrenCourseStructures(props);
    initAnswers(childrenCourseStructures);
    // Container startup ends

    var script = null; // Lib script
    var krpano = null; // Lib object
    const panoramaId = "panorama" + props.courseStructure.id;
    const fullScreenRef = ref(null);
    const showableCourseStructure = ref(null);
    const showModal = ref(false);

    const { setFullscreenRef, openFullscreen } = useFullscreen();
    setFullscreenRef(fullScreenRef);

    if (!window.krpanoJS) {
      loadScript();
    }

    function loadScript() {
      script = document.createElement("script");
      script.src = "/lib/krpano/krpano.js";
      document.head.appendChild(script);
    }

    onMounted(() => {
      if (script) {
        script.onload = () => {
          loadPanorama();
        };
      } else {
        loadPanorama();
      }
    });

    function loadPanorama() {
      if (window.embedpano) {
        window.embedpano({
          swf: null,
          xml: null,
          target: panoramaId,
          onready: krpano_ready,
        });
      }
    }

    function krpano_ready(krpano_interface) {
      krpano = krpano_interface;
      loadxmlstring(props.data.xmlstring); // Required to draw first before hotspots
      createHotspots(props.data.hotspots);
    }

    function createHotspots(hotspots) {
      let hotspotId = null;
      for (let i in hotspots) {
        hotspotId = addHotspot(hotspots[i].courseStructureId, "", hotspots[i].hotspot.hl, hotspots[i].hotspot.vl);
        addHotspotOnClickListener(hotspotId, hotspots[i].courseStructureId, getCourseStructure);
      }
    }

    function addHotspot(id, image, h, v) {
      if (id) {
        id = "hs" + id;
      } else {
        id = "hs" + ((Date.now() + Math.random()) | 0); // create unique/randome name
      }
      if (!image) {
        image = "/lib/krpano/vtourskin_hotspot.png";
      }
      krpano.call("addhotspot(" + id + ")");
      krpano.set("hotspot[" + id + "].url", image);
      krpano.set("hotspot[" + id + "].ath", h);
      krpano.set("hotspot[" + id + "].atv", v);
      krpano.set("hotspot[" + id + "].distorted", true);

      return id;
    }

    function getCourseStructure(course_structure_id) {
      let cs = null;
      if (childrenCourseStructures) {
        cs = CourseStructureHelper.getCourseStructureById(childrenCourseStructures, course_structure_id);
      }
      if (cs) {
        showableCourseStructure.value = cs;
        showModal.value = true;
      }
    }

    function foo(param, defaultParam) {
      const cleanParam = { ...defaultParam, ...param };
      return cleanParam;
    }

    function addHotspotOnClickListener(hotspotId, value, callback) {
      if (krpano.get("device.html5")) {
        const defaultHotspotSettings = {
          showContentAfterMs: 0,
          looktohotspot: null,
          data: null,
        };
        const hotspotSettings = foo(props.data.globalHotspotSettings, defaultHotspotSettings);

        krpano.set(
          "hotspot[" + hotspotId + "].onclick",
          function (v) {
            if (hotspotSettings.looktohotspot) {
              krpano.call("looktohotspot(" + hotspotId + "," + hotspotSettings.looktohotspot.fov + ")");
            }
            if (hotspotSettings.showContentAfterMs === 0) {
              callback(v);
            } else {
              setTimeout(() => {
                callback(v);
              }, hotspotSettings.showContentAfterMs);
            }
          }.bind(null, value)
        );
      }
    }

    function loadxmlstring(xmlstring) {
      if (krpano) {
        krpano.call("loadxml(" + escape(xmlstring) + ", null, MERGE, BLEND(0.5));");
      }
    }

    function close() {
      showModal.value = false;
    }

    return {
      panoramaId,

      // Full screen
      fullScreenRef,
      openFullscreen,

      // Modal
      showModal,
      close,
      showableCourseStructure,
    };
  },
};
</script>

<style lang="scss" scoped>
.panorama {
  width: 600px;
  height: 400px;
  max-width: 100%;
  max-height: 100%;
}

.fullScreen {
  &.onFullscreenApi,
  &.onFallback {
    .panorama {
      height: 100%;
      width: 100%;
    }
  }
}
</style>