<template>
    <div class="videoToolbar" :class="{'hide': hideVideoToolbar}">
        <div class="videoProgressBar">
            <div class="currentProgress" :style="{width: videoProgressPercentage+'%'}"></div>
        </div>
        <div class="videoControls">
            <div class="togglePlay" @click="togglePlay">
                <svg v-if="!isPlaying" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-play-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg>
                <svg v-else width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pause-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 3.5A1.5 1.5 0 0 1 7 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5zm5 0A1.5 1.5 0 0 1 12 5v6a1.5 1.5 0 0 1-3 0V5a1.5 1.5 0 0 1 1.5-1.5z"/>
                </svg>
            </div>
            <div class="handleVolume">
                <div class="toggleVolume" @click="toggleVolume">
                    <svg v-if="volume > 0" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-volume-down-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M8.717 3.55A.5.5 0 0 1 9 4v8a.5.5 0 0 1-.812.39L5.825 10.5H3.5A.5.5 0 0 1 3 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
                        <path d="M10.707 11.182A4.486 4.486 0 0 0 12.025 8a4.486 4.486 0 0 0-1.318-3.182L10 5.525A3.489 3.489 0 0 1 11.025 8c0 .966-.392 1.841-1.025 2.475l.707.707z"/>
                    </svg>
                    <svg v-else width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-volume-mute-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06zm7.137 2.096a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708l4-4a.5.5 0 0 1 .708 0z"/>
                        <path fill-rule="evenodd" d="M9.146 5.646a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0z"/>
                    </svg>
                </div>
                <div class="volumeControl">
                    <input type="range" min="0" max="100" @input="changeVolume">
                </div>
            </div>
            <div class="toggleFullscreen" @click="toggleFullscreen">
                <svg v-if="!isFullscreenOn" width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-fullscreen" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
                </svg>
                <svg v-else width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-fullscreen-exit" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" d="M5.5 0a.5.5 0 0 1 .5.5v4A1.5 1.5 0 0 1 4.5 6h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5zm5 0a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 10 4.5v-4a.5.5 0 0 1 .5-.5zM0 10.5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 6 11.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zm10 1a1.5 1.5 0 0 1 1.5-1.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4z"/>
                </svg>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VideoToolbar',
    props: [
        'hideVideoToolbar', 'videoProgressPercentage',
        'isPlaying', 'isFullscreenOn',
        'toggleVolume', 'changeVolume', 'volume',
        'togglePlay', 'toggleFullscreen',
    ],
}
</script>

<style lang="scss" scoped>

    .videoToolbar {
        position: absolute;
        background: rgba(0,0,0,0.5);
        z-index: 155;
        bottom: 0;
        height: 50px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        opacity: 1;
        transition: opacity 0.3s ease;

        &.hide {
            opacity: 0;
        }
    }

    .videoProgressBar {
        width: 100%;
        background: white;
        height: 5px;
        
        .currentProgress {
            background: red;
            width: 0%;
            height: inherit;
        }
    }

    .videoControls {
        display: flex;
        align-items: center;
        padding-top: 5px;

        > div {
            color: var(--theme-color-first);
            //cursor: pointer;
            font-size: 20px;
            display: flex;
            align-items: center;
        }
        .handleVolume {
            svg {
                font-size: 30px;
            }

            .toggleVolume {
                cursor: pointer;
                width: 40px;
            }

            .bi-volume-mute-fill {
                margin-left: 3.8px;
            }
            
            .volumeControl {
                //visibility: hidden;
                display: flex;
                opacity: 0;
                transition: opacity 0.3s ease;
                
                input {
                    width: 100px;
                    padding: 0;
                    margin-bottom: 0;
                    display: inline-block;
                    cursor: pointer;

                    height: 5px;

                    &::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        width: 15px;
                        height: 15px;
                        background: white;
                        border: 0;
                        border-radius: 50px;
                        cursor: pointer;
                    }

                    &::-moz-range-thumb {
                        width: 15px;
                        height: 15px;
                        background: white;
                        border: 0;
                        border-radius: 50px;
                        cursor: pointer;
                    }
                }
            }
            &:hover > .volumeControl {
                opacity: 1;
                //visibility: visible;
            }
        }
        .togglePlay {
            cursor: pointer;
            font-size: 30px;
        }
    }
    .toggleFullscreen {
        cursor: pointer;
        margin-left: auto;
    }
</style>