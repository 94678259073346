// Add polyfills
import smoothscroll from 'smoothscroll-polyfill';

import { createApp } from 'vue';
import App from './App.vue'

import * as TaitaenCore from '@moods/taitaen-core'

import './assets/styles/app.scss'

import setAppConfig from './setAppConfig'

import router from './router';

// Create app
const app = createApp(App);

// Kick off the polyfill!
smoothscroll.polyfill();

// SETUP TAITAEN CORE
const { ErrorHandler, Store } = TaitaenCore;

setAppConfig();

// Set error handler
if (process.env.NODE_ENV !== 'development') {
  app.config.errorHandler = (err, vm, info) => {
    ErrorHandler.postError({
      err: ErrorHandler.getTypeError(err),
      vm: vm,
      info: info,
    })
  }
}


app.use(router);

app.mount('#app');

// Redirect to login page if unauthenticated
Store.subscribe('User/token', (newValue) => {
  if (!newValue) {
    router.push({ name: 'Login' }).catch(() => { });
  }
})
