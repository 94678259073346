export const VideoPlayerState = {
  NULL: 0,
  STARTED: 1,
  PLAYING: 2,
  PAUSED: 3,
  ENDED: 4,
  BUFFERING: 5,
  CUED: 6,
}

export function getSourceByUrl(url) {
  if (url.indexOf('youtube') || url.indexOf('youtu.be')) {
    return 'youtube';
  } else if (url.indexOf('vimeo')) {
    return 'vimeo';
  }
  return 'html';
}