<template>
  <h1 v-if="courseStructure.name">{{ Hyphenator.getHyphenatedText(courseStructure.name) }}</h1>

  <ShowPreviousMaterialButton v-if="!isTest && !isInsideContainer && showPreviousMaterial" :showPreviousMaterial="showPreviousMaterial"/>  

  <TemplateRenderer
    v-if="
      courseStructure.templateContentJson &&
      courseStructure.templateContentJson !== 'null'
    "
    :templateJson="JSON.parse(courseStructure.templateContentJson)"
  />
  <div v-else v-html="courseStructure.structureRefData.htmlContent"></div>
</template>

<script>
import TemplateRenderer from "@tools/template-renderer/TemplateRenderer";
import ShowPreviousMaterialButton from './ShowPreviousMaterialButton'
import Hyphenator from "@utils/Hyphenator";

export default {
  name: "RenderCourseStructureContent",
  components: {
    TemplateRenderer,
    ShowPreviousMaterialButton
  },
  props: ["isTest", "courseStructure", "showPreviousMaterial", "isInsideContainer"],
  setup() {
    return {
      Hyphenator
    }
  }
};
</script>
