import { onMounted, onBeforeUnmount, ref } from 'vue';

import FullscreenAPI from '@utils/FullscreenAPI'

export function useFullscreen() {
    const isFullscreenOn = ref(false);
    const useFullscreenAPI = ref(true);
    var fullscreenRef = null;
    var fullscreenchangePrefixes = ['', 'ms', 'moz', 'webkit'];

    onMounted(() => {
        //if (!useFullscreenAPI.value) {
        //if (!FullscreenAPI.isFullscreenEnabled()) {
            document.addEventListener('keydown', onKeydown);
        //}
        //}

        if (useFullscreenAPI.value && fullscreenRef.value) {
            for (let i in fullscreenchangePrefixes) {
                fullscreenRef.value.addEventListener(fullscreenchangePrefixes[i]+'fullscreenchange', onFullscreenChange);
            }
        }
    })

    onBeforeUnmount(() => {
        //if (!useFullscreenAPI.value) {
        //if (!FullscreenAPI.isFullscreenEnabled()) {
            document.removeEventListener('keydown', onKeydown)
        //}
        //}

        if (useFullscreenAPI.value) {
            for (let i in fullscreenchangePrefixes) {
                fullscreenRef.value.removeEventListener(fullscreenchangePrefixes[i]+'fullscreenchange', onFullscreenChange);
            }
        }
    })

    function onFullscreenChange() {
        if (FullscreenAPI.isFullscreenOn()) {
            isFullscreenOn.value = true;
            fullscreenRef.value.classList.add('onFullscreenApi');
        } else {
            isFullscreenOn.value = false;
            fullscreenRef.value.classList.remove('onFullscreenApi');
        }
    }

    function onKeydown(e) {
        if (e.key === 'Escape') {
            closeFullscreen();
        }
    }

    function setFullscreenRef(htmlElement) {
        fullscreenRef = htmlElement;
    }

    function toggleFullscreen() {
        if (!isFullscreenOn.value) {
            openFullscreen();
        } else {
            closeFullscreen();
        }
    }

    function openFullscreen() {
        if (!isFullscreenOn.value) { // For fallback
            isFullscreenOn.value = true;
        }

        // Fullscreen API
        if (FullscreenAPI.isFullscreenEnabled() && useFullscreenAPI.value) {
            let result = FullscreenAPI.openElement(fullscreenRef.value);
            if (result || result === undefined) {
                return;
            }
        }

        // Fallback
        openFallbackFullscreen();
    }

    function openFallbackFullscreen() {
        fullscreenRef.value.classList.add('onFallback');
    }
    function closeFallbackFullscreen() {
        fullscreenRef.value.classList.remove('onFallback');
    }

    function closeFullscreen() {
        if (isFullscreenOn.value) { // For fallback
            isFullscreenOn.value = false;
        }

        // Fullscreen API
        if (FullscreenAPI.isFullscreenEnabled() && useFullscreenAPI.value) {
            FullscreenAPI.close();
            return;
        }

        // Fallback
        closeFallbackFullscreen();
    }

    return {
        isFullscreenOn,
        useFullscreenAPI,
        setFullscreenRef,
        toggleFullscreen,
        openFullscreen,
        closeFullscreen,
    }
}
