import { getKeyByValue } from './General'

export const StructureTypes = {
  NULL: 0,
  CONTENT: 1,
  TASK: 2,
  ADVANCED: 10,
}
export const TaskTypes = {
  NULL: 0,
  SINGLESELECT: 1,
  MULTISELECT: 2,
  ORDER_THE_LIST: 3,
  VIDEO: 4,
  DRESS_UP_THE_CHARACTER: 5, // Custom task
  DIAL_THE_NUMBER: 6, // Custom task
  ORDER_THE_LETTERS: 7, // Custom task
  HANGMAN: 8, // Custom task
  COMBINE: 9, // Custom task
}

// export const TrainingProgressState = {
//     NULL: 0,
//     NOT_STARTED: 1,
//     IN_PROGRESS: 2,
//     NOT_PASSED: 3,
//     PASSED: 4,
// }

export const CourseSettings = {
  NULL: 0,
  SHOW_RESULT_AFTER_TASK: 'showResultAfterTask',
  SHOW_RESULT_AFTER_COURSE: 'showResultAfterCourse',
  ALLOW_WRONG_ANSWERS: 'allowWrongAnswers',
  STORE_ANSWERS: 'storeAnswers',
}

export function getTypeByStructureType(structureType) {
  return getKeyByValue(StructureTypes, structureType);
  //return Object.keys(StructureTypes).find(key => StructureTypes[key] === structureType);    
}

export function getTypeByTaskType(taskType) {
  return getKeyByValue(TaskTypes, taskType);
  //return Object.keys(StructureTypes).find(key => StructureTypes[key] === structureType);    
}

export function getComponentNameByTypeId(typeId) {
  let taskTypesByKey = getTaskTypesByKey();
  if (taskTypesByKey[typeId]) {
    return taskTypesByKey[typeId];
  }
  return null;
}

export function getTaskTypesByKey() {
  return {
    [TaskTypes.MULTISELECT]: 'Select',
    [TaskTypes.SINGLESELECT]: 'Select',
    [TaskTypes.ORDER_THE_LIST]: 'OrderTheList',
    [TaskTypes.VIDEO]: 'Video',
    [TaskTypes.DRESS_UP_THE_CHARACTER]: 'DressUpTheCharacter', // Custom
    [TaskTypes.DIAL_THE_NUMBER]: 'DialTheNumber', // Custom
    [TaskTypes.ORDER_THE_LETTERS]: 'OrderTheLetters', // Custom
    [TaskTypes.HANGMAN]: 'Hangman', // Custom,
    [TaskTypes.COMBINE]: 'Combine', // Custom
  }
  /*
  return {
      checkboxes: 'Checkboxes',
      right_wrong: 'RightWrong',
      order_the_list: 'OrderTheList',
      dial_the_number: 'DialTheNumber',
      select_answers: 'SelectAnswers',
      dress_up_the_character: 'DressUpTheCharacter',

      //drag_and_drop: 'DragAndDrop',
      //dress_up_the_character: 'DressUpTheCharacter',
  }
  */
}
