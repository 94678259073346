<template>
  <VideoVimeo
    :id="id"
    :options="videoOptions"
    :videoId="videoId"
    :play="play"
    @player="videoPlayer"
    @is-paused="isPaused"
  >
    <template v-slot:toolbar>
      <VideoToolbar
        :hideVideoToolbar="hideVideoToolbar"
        :videoProgressPercentage="videoProgressPercentage"
        :isPlaying="isPlaying"
        :isFullscreenOn="isFullscreenOn"
        :togglePlay="togglePlay"
        :toggleVolume="toggleVolume"
        :changeVolume="changeVolume"
        :volume="volume"
        :toggleFullscreen="toggleFullscreen"
      />
    </template>
  </VideoVimeo>
</template>

<script>
import { ref, computed, watch } from "vue";

import VideoVimeo from "@tools/video/VideoVimeo";

import VideoToolbar from "./VideoToolbar";

import VideoState from "./useVideoState";

import { VideoPlayerState } from "@utils/Video";

export default {
  name: "Video",
  components: {
    VideoToolbar,
    VideoVimeo,
  },
  props: ["id", "options", "videoId", "play"],
  setup(props) {
    var videoOptions = {
      id: props.videoId,
      autoplay: false,
      controls: false,
    };

    const { videoState } = VideoState();

    const videoProgressPercentage = ref(0);

    watch(
      () => videoState.volume,
      () => {
        player.setVolume(videoState.volume / 100);
      }
    );

    var isVideoStarted = false;
    var player;
    function videoPlayer(_player) {
      player = _player;

      player.setVolume(videoState.volume / 100);

      player.on("playing", function () {
        if (!isVideoStarted) {
          isVideoStarted = true;
          videoState.state = VideoPlayerState.STARTED;
        }

        videoState.play = true;
      });
      player.on("pause", function () {
        videoState.state = VideoPlayerState.PAUSED;
        videoState.play = false;
      });
      player.on("ended", function () {
        videoState.state = VideoPlayerState.ENDED;
      });
      player.on("timeupdate", function (data) {
        videoState.progressTimeSeconds = data.seconds;

        videoProgressPercentage.value = data.percent * 100;
      });

      //getVideoProgress();
    }

    // function getVideoProgress() {
    //     player.on('timeupdate', function(data) {
    //         videoProgressPercentage.value = data.percent*100;
    //     })
    // }

    function togglePlay() {
      if (videoState.play) {
        player.pause();
        return;
      }
      player.play();
    }

    function toggleVolume() {
      player
        .getVolume()
        .then(function (volume) {
          if (volume > 0) {
            videoState.lastVolume = volume;
            videoState.volume = 0;
          } else {
            videoState.volume = videoState.lastVolume;
          }
        })
        .catch(() => {});
    }

    function changeVolume(e) {
      videoState.volume = e.target.value / 100;
    }

    return {
      // Pass as props for video component
      videoOptions,
      videoPlayer,

      // Video toolbar
      isFullscreenOn: computed(() => videoState.isFullscreenOn),
      toggleFullscreen: videoState.toggleFullscreen,
      togglePlay,
      toggleVolume,
      changeVolume,
      hideVideoToolbar: computed(() => videoState.hideVideoToolbar),
      videoProgressPercentage: computed(() => videoProgressPercentage.value),
      isPlaying: computed(() => videoState.play),
      volume: computed(() => videoState.volume),
    };
  },
};
</script>
