<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <div v-if="loading" class="flexCenter loaderPadding"><Loading :loading="loading" /></div>
          <div v-if="!loading">
            <div class="container" style="color: black">
              <h2>Viestit</h2>
              <div v-if="messages.length === 0">
                <p>Ei viestejä</p>
              </div>
              <div v-for="(message, index) in messages" :key="index">
                <Message :message="message" :reloadMessages="getMessages" />
              </div>
              <div>
                <ReplyForm :topicId="topicId" :reloadMessages="getMessages" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import Loading from "Loading";
import Message from "./Message";
import ReplyForm from "./ReplyForm";
import { Store } from "@moods/taitaen-core";
import { useRoute } from "vue-router";

export default {
  name: "MessageBoards",
  components: {
    Loading,
    ReplyForm,
    Message,
  },

  setup() {
    const route = useRoute();
    const loading = ref(false);

    const topicId = ref(route.params.topicId);
    const messages = ref([]);

    onBeforeMount(() => {
      loading.value = true;
      getMessages(topicId.value);
    });

    function getMessages() {
      Store.dispatch("MessageBoards/getMessages", topicId.value).then(
        (s) => {
          messages.value = s.data;
          loading.value = false;
        },
        (e) => {
          console.error("Something failed: ", e);
          loading.value = false;
        }
      );
    }

    return {
      loading,
      topicId,
      messages,
      getMessages,
    };
  },
};
</script>