<template>
  <!-- <template v-for="(row, rowIdx) in templateJson.Content" :key="rowIdx"> -->
  <template v-for="(row, rowIdx) in templateJson" :key="rowIdx">
    <div
      :class="{
        'container-fluid': row.options.width === 'ROW_SIZE_ENUM.FULL',
        container: row.options.width === 'ROW_SIZE_ENUM.WIDE',
      }"
      style="padding-left: 0; padding-right: 0"
    >
      <TemplateRow :row="row" />
    </div>
  </template>
</template>

<script>
import TemplateRow from "./TemplateRow.vue";

export default {
  name: "TemplateRenderer",
  components: {
    TemplateRow,
  },
  props: ["templateJson"],
  setup() {
    const ROW_SIZE_ENUM = {
      NULL: 0,
      FULL: 1,
      WIDE: 2,
    };

    // function isFull(width) {
    //   if (width === ROW_SIZE_ENUM.FULL) {
    //     return true;
    //   }
    //   return false;
    // }

    // function isWide(width) {
    //   if (width === ROW_SIZE_ENUM.WIDE) {
    //     return true;
    //   }
    //   return false;
    // }

    return {
      ROW_SIZE_ENUM,
    };
  },
};
</script>
