<template>
  <div id="layout">
    <Header />
    <Main />
    <Footer />
  </div>
</template>

<script>
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";

export default {
  name: "Layout",
  components: {
    Header,
    Main,
    Footer,
  },
};
</script>

