<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <h1>Tietosuojaseloste</h1>                      
        <p>Päivitetty viimeksi: 16.11.2021</p>
        <p>&nbsp;</p>
        <h2>1. Rekisterinpitäjä</h2>
        <p>Nimi: Moods Oy</p>
        <p>Y-tunnus: 2287262-7</p>
        <p>Osoite: Yliopistonkatu 35 D, 20100 Turku</p>
        <h2>2. Yhteyshenkilö rekisteriä koskevissa asioissa</h2>
        <p>Nimi: Tiina Koivula</p>
        <p>Puhelin: +358 50 082 4606</p>
        <p>Sähköposti: tiina.koivula@moods.fi</p>
        <h2>3. Rekisterin nimi</h2>
        <p>Taitaen käyttäjärekisteri</p>
        <h2>4. Henkilötietojen käsittelyn tarkoitus</h2>
        <p>Henkilötietoja käsitellään palvelussa käyttäjän tunnistamista ja käyttäjälle kuuluvan tiedon näyttämistä varten. Käyttäjien tietoja käytetään koulutuksen suorittamiseen liittyvässä raportoinnissa. Henkilötietoja hyödynnetään myös palvelun julkaisujärjestelmän käyttöoikeuksien ylläpitämiseen. Henkilötietojen käsittely perustuu henkilötietolain 2 luvun 8 §:n 5. ja 6. kohtaan.</p>
        <h2>5. Rekisterin tietosisältö</h2>
        <p>Rekisterin tietosisältö koostuu tapauskohtaisesti määritellyistä henkilötiedoista, useimmiten kuitenkin vähintään sähköpostista. Muita tietoja voivat esimerkiksi olla nimi, osoite ja puhelinnumero.</p>
        <h2>6. Palvelun muu tietosisältö</h2>
        <p>Palvelun muun henkilötietosisällön keräämisessä oikeusperusteena käytetään henkilön omaa suostumusta.</p>
        <h3>6.2. Lomakkeet</h3>
        <p>Jotkut lomakkeet voivat edellyttää henkilötietojen syöttämistä. Varsinaiset yhteydenottolomakkeet eivät tallenna henkilötietoja rekisteriin.</p>
        <h3>6.3. Evästeet</h3>
        <p>Rekisterinpitäjän verkkosivut käyttävät omia ja kolmansien osapuolien evästeitä käyttäjäystävällisyyden parantamiseksi. Evästeet keräävät tietoa esimerkiksi verkkosivulla vierailevien kävijöiden liikkumisesta ja käyttäytymisestä. Jotkut evästeet saattavat olla pakollisia rekisterinpitäjän verkkosivujen toimivuuden kannalta.</p>
        <p>Kirjautumisen yhteydessä asetamme tunnistautumiseen liittyvät evästeet. Kirjautumisevästeet poistetaan kahden vuorokauden kuluessa, jos "Muista minut" -valintaa ei ole tehty. Mikäli “Muista minut” on valittu kirjautumisen yhteydessä, kirjautumistietoja säilytetään kahden viikon ajan. Kirjautumiseen liittyvät evästeet poistetaan uloskirjautumisen yhteydessä.</p>
        <h3>6.4. Muilta sivustoilta upotettu sisältö</h3>
        <p>Sivusto voi sisältää upotettua sisältöä (esimerkiksi videoita, kuvia, artikkeleita jne.). Toisilta sivustoilta tuodun upotetun sisällön avaaminen on verrattavissa siihen, että vierailija itse kävisi kolmannen osapuolen sivustolla.</p>
        <p>Nämä sivustot voivat kerätä tietoa sinusta, käyttää evästeitä, upottaa kolmannen osapuolen seurantaevästeitä ja monitoroida vuorovaikutustasi upotetun sisällön kanssa, mukaan lukien vuorovaikutuksen seuranta jos ja kun olet kirjautunut käyttäjänä sivustolle.</p>
        <h2>7. Säännönmukaiset tietolähteet</h2>
        <p>Henkilötietoja kerätään käyttäjien rekisteröitymisen ja tietyissä tapauksissa integroidun tunnistautumisen yhteydessä käyttäjärekisteristä ja jotkut lomakkeet voivat edellyttää henkilötietoja.</p>
        <h2>8. Säännönmukaiset tietojen luovutukset</h2>
        <p>Henkilötietoja käsittelevät rekisterinpitäjä ja rekisterinpitäjän lukuun henkilötietojen käsittelijät. Henkilötietoja ei luovuteta ulkopuolisille tahoille, mutta poikkeuksen muodostavat viranomaistahot lakisääteisissä tapauksissa.</p>
        <h2>9. Tietojen siirto EU:n tai Euroopan talousalueen ulkopuolelle</h2>
        <p>Tietojen käsittelemisessä käytettävä palvelin on rekisterinpitäjän käyttämän ulkopuolisen palveluntarjoajan omistuksessa ja hallinnassa. Rekisterinpitäjän käyttämä palveluntarjoaja on vastuussa siitä, että tiedot sijaitsevat EU:n tai Euroopan talousalueen sisällä.</p>
        <h2>10. Henkilötietojen säilytys</h2>
        <p>Henkilötietoja säilytetään niin kauan kuin on tarpeellista. Tarpeettomat tiedot hävitetään.</p>
        <h2>11. Rekisterin suojauksen periaatteet</h2>
        <p>Rekisterinpitäjän palvelun tiedot välittyvät käyttäen suojattua yhteyttä. Rekisterinpitäjän käyttämän verkkosivujen palvelimen palveluntarjoaja vastaa palvelimen asianmukaisesta suojauksesta.</p>
        <p>Varsinainen rekisteri on myös ulkopuolisen palveluntarjoajan omistuksessa ja hallinnassa ja palveluntarjoaja vastaa rekisterin teknisestä suojauksesta. Varsinainen rekisteri edellyttää tunnistautumista rajatuilla käyttöoikeuksilla.</p>
        <h2>12. Rekisteröidyn tietojen korjaus-, tarkastus- ja kielto-oikeus</h2>
        <p>Rekisteröidyllä on oikeus tarkistaa mitä tietoja hänestä on tallennettu rekisteriin. Tarkastusoikeutta voi käyttää maksuttomasti kerran vuodessa. Rekisteröidyllä on myös oikeus pyytää tietojensa korjausta ja hävittämistä.</p>
        <p>Tietojen korjaus-, tarkastus- ja kieltopyyntö tulee tehdä kirjallisena rekisterin yhteyshenkilölle ja henkilöllisyys tarkastetaan kuvallisella ja voimassa olevalla virallisella henkilöllisyystodistuksella.</p>
        <h2>13. Kolmannen osapuolen palveluista ja rekistereistä</h2>
        <p>Kolmannen osapuolen palvelut voivat kerätä tietoja kävijöistä voimassaolevien ehtojensa mukaisesti.</p>
        <!-- <h3>13.1. Google</h3>
        <p>Rekisterinpitäjän verkkosivujen analytiikkatietoja kerää Google LLC:n palvelu Google Analytics. Analytiikkatietoja kerätään palvelun kehittämiseksi ja parantamiseksi. Googlen tietosuojakäytäntö on luettavissa osoitteessa: <a href="https://policies.google.com/privacy?hl=fi" target="_blank" rel="noopener">https://policies.google.com/privacy?hl=fi</a></p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
