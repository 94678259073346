<template>
  <VideoYoutubeSimple
    :id="id"
    :videoId="videoId"
    :options="videoOptions"
    :play="play"
    @player="videoPlayer"
    @on-player-ready="onPlayerReady"
    @state-changed="videoStateChanged"
    @videotime="getVideotime"
  >
    <template v-slot:toolbar>
      <VideoToolbar
        :hideVideoToolbar="hideVideoToolbar"
        :videoProgressPercentage="videoProgressPercentage"
        :isPlaying="isPlaying"
        :isFullscreenOn="isFullscreenOn"
        :togglePlay="togglePlay"
        :toggleVolume="toggleVolume"
        :changeVolume="changeVolume"
        :volume="volume"
        :toggleFullscreen="toggleFullscreen"
      />
    </template>
  </VideoYoutubeSimple>
</template>

<script>
import { ref, computed, watch } from "vue";

import VideoYoutubeSimple from "@tools/video/VideoYoutubeSimple";

import VideoToolbar from "./VideoToolbar";

import VideoState from "./useVideoState";

import { VideoPlayerState } from "@utils/Video";

export default {
  name: "Video",
  components: {
    VideoToolbar,
    VideoYoutubeSimple,
  },
  props: ["id", "options", "videoId", "play"],
  setup() {
    var videoOptions = {
      autoplay: 0,
      playsinline: 1,
      controls: 0,
      rel: 0,
    };

    const { videoState } = VideoState();

    const videoProgressPercentage = ref(0);

    watch(
      () => videoState.volume,
      () => {
        player.setVolume(videoState.volume);
      }
    );

    var videotime = 0;
    var duration = 0;

    var player;
    function videoPlayer(_player) {
      player = _player;
    }

    function onPlayerReady() {
      duration = player.getDuration();
      player.setVolume(videoState.volume);
    }

    function getVideotime(_videotime) {
      videotime = _videotime;

      watch(
        () => videotime.value,
        () => {
          videoState.progressTimeSeconds = videotime.value;

          videoProgressPercentage.value =
            (videoState.progressTimeSeconds / duration) * 100;
        }
      );
    }

    function getStateByYoutube(state, YtPlayerState) {
      switch (state) {
        case YtPlayerState.UNSTARTED:
          return VideoPlayerState.STARTED;

        case YtPlayerState.ENDED:
          return VideoPlayerState.ENDED;

        case YtPlayerState.PLAYING:
          return VideoPlayerState.PLAYING;

        case YtPlayerState.PAUSED:
          return VideoPlayerState.PAUSED;

        case YtPlayerState.BUFFERING:
          return VideoPlayerState.BUFFERING;

        case YtPlayerState.CUED:
          return VideoPlayerState.CUED;
      }
    }

    function videoStateChanged(state, YtPlayerState) {
      videoState.state = getStateByYoutube(state, YtPlayerState);

      switch (videoState.state) {
        case VideoPlayerState.PAUSED:
          videoState.play = false;
          break;

        case VideoPlayerState.PLAYING:
          videoState.play = true;
          break;
      }
    }

    function togglePlay() {
      var state = player.getPlayerState();

      if (state === 1) {
        player.pauseVideo();
        return;
      }
      player.playVideo();
    }

    function toggleVolume() {
      let volume = player.getVolume();

      if (volume > 0) {
        videoState.lastVolume = volume;
        videoState.volume = 0;
        //setPlayerVolume();
      } else {
        videoState.volume = videoState.lastVolume;
        //setPlayerVolume();
      }
    }

    function changeVolume(e) {
      videoState.volume = e.target.value;
      //setPlayerVolume();
    }

    return {
      // Pass as props for video component
      videoOptions,
      videoPlayer,
      onPlayerReady,
      videoStateChanged,
      getVideotime,

      // Pass as props for toolbar
      isFullscreenOn: computed(() => videoState.isFullscreenOn),
      toggleFullscreen: videoState.toggleFullscreen,
      togglePlay,
      toggleVolume,
      changeVolume,
      hideVideoToolbar: computed(() => videoState.hideVideoToolbar),
      videoProgressPercentage: computed(() => videoProgressPercentage.value),
      isPlaying: computed(() => videoState.play),
      volume: computed(() => videoState.volume),
    };
  },
};
</script>

