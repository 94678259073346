<template>
  <div>
    <VimeoHandler
      v-if="videoType === 'vimeo'"
      :id="id"
      :options="options"
      :videoId="videoId"
      :play="play"
      videoStateChanged="videoStateChanged"
    />
    <YoutubeHandler
      v-else-if="videoType === 'youtube'"
      :id="id"
      :options="options"
      :videoId="videoId"
      :play="play"
      videoStateChanged="videoStateChanged"
    />
  </div>
</template>

<script>
import { computed } from "vue";

import VimeoHandler from "./VimeoHandler";
import YoutubeHandler from "./YoutubeHandler";

import useVideoState from "./useVideoState";

export default {
  name: "Video",
  props: ["id", "options", "videoType", "videoId"],
  components: {
    VimeoHandler,
    YoutubeHandler,
  },
  setup() {
    const { videoState } = useVideoState();

    return {
      play: computed(() => videoState.play),
    };
  },
};
</script>
