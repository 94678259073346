const InjectYoutubeApi = {
  isSet: false,
  isApiReady: false,

  check() {
    return new Promise((resolve) => {
      if (this.isSet && this.isApiReady) {
        resolve(this.isApiReady);
      }
      if (!this.isSet) {
        this.isSet = true;
        this.injectApi();
        // This code loads the IFrame Player API code asynchronously.
        window.onYouTubeIframeAPIReady = () => {
          this.isApiReady = true;
          resolve(this.isApiReady);
        }
      }
    })
  },

  injectApi() {
    // This function creates an <iframe> (and YouTube player) after the API code downloads.
    var tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }
}
export default InjectYoutubeApi