import { ref } from 'vue'

const trainingHeaderRef = ref(null);

export default function useTrainingHeaderElement() {

  function scrollToTop() {
    if (trainingHeaderRef.value) {
      setTimeout(() => {
        if (trainingHeaderRef.value) {
          window.scrollTo({ top: trainingHeaderRef.value.offsetTop, behavior: "smooth" });
        }
      }, 0);
    }
  }

  return {
    trainingHeaderRef,
    scrollToTop
  }
}