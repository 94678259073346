<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <h1>Ohje</h1>
          <!-- <p>Varaa koulutukseen riittävästi aikaa (n. 30 - 60 min) sekä häiriötön ympäristö, jossa suoritat koulutuksen. Koulutus muodostuu info-osiosta ja erilaisista tehtävistä, joissa pääset testaamaan osaamistasi. Huomioi, että Hyvä tietää -osiot eivät sisällä tehtävänantoa.</p> -->
          <p>Varaa koulutukseen riittävästi aikaa sekä häiriötön ympäristö, jossa suoritat koulutuksen. Koulutus muodostuu info-osiosta ja erilaisista tehtävistä, joissa pääset testaamaan osaamistasi. Huomioi, että Hyvä tietää -osiot eivät sisällä tehtävänantoa.</p>
          <p>Voit suorittaa koulutusosiot haluamassasi järjestyksessä ja voit keskeyttää koulutuksen koska tahansa. Vastaukset tallentuvat automaattisesti järjestelmään, joten voit jatkaa sinulle sopivana ajankohtana.</p>
          <p>Suorituksesi kirjataan hyväksytyksi kun olet käynyt kaikki osiot läpi. Suorita se koulutusosio uudelleen, johon vastaat väärin.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Help',
};
</script>
