import * as TaitaenCore from '@moods/taitaen-core'
import envMode from './envMode';

export default function setAppConfig() {
  const { AppConfig } = TaitaenCore;

  if (window.appConfig) {
    AppConfig.set({
      name: window.appConfig.name,
      environmentMode: window.appConfig.environmentMode,
      backendBaseUrl: window.appConfig.backendBaseUrl,
      logErrorUrl: window.appConfig.logErrorUrl,
      tenantKey: window.appConfig.tenantKey,
    })

  } else {
    let environmentMode = envMode.NULL;
    if (process.env.NODE_ENV === 'development') {
      environmentMode = envMode.DEVELOPMENT;
    } else if (process.env.NODE_ENV === 'production') {
      environmentMode = envMode.PRODUCTION;
    }

    AppConfig.set({
      name: process.env.VUE_APP_NAME,
      environmentMode: environmentMode,
      backendBaseUrl: process.env.VUE_APP_BASE_URL,
      logErrorUrl: process.env.VUE_APP_LOG_ERROR_URL,
      tenantKey: process.env.VUE_APP_TENANT_KEY,
    })
  }
}