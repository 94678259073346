<template>
  <div>
    <template v-if="!loading">
      <TrainingHeader />
      <!--<div class="secondColor">{{currentCourse}}</div>-->
      <template v-if="renderCourse.courseStructure.length > 0">
        <div id="trainingBody" class="container-fluid">
          <DemoModeToggle v-if="isTestModeAllowed || isTestModeEnabled"/>
          <SwegonLeaf v-if="renderShowResult"/>
          <div id="trainingProgressList">
            <TrainingProgressList />
          </div>

          <!--
                    <div class="secondColor">AnimationState: {{animationState}}</div>
                    <div class="secondColor">isVisible: {{isVisible}}</div>
          -->
          <transition name="fade" mode="out-in" @enter="enter" @after-leave="afterLeave">
            <div v-if="animationState">
              <div v-if="isVisible">
                <template v-if="!renderShowResult">
                  <Training />
                </template>
                <template v-else>
                  <Result />
                </template>
              </div>
            </div>
          </transition>
        </div>
      </template>
      <template v-else>
        <div id="emptyCourse" class="container-fluid">
          <h1>Kurssilla ei ole sisältöä</h1>
          <HomeButton :to="{ name: 'Training', params: { trainingId: renderTrainingId } }" />
          <!-- <router-link class="button2" :to="{name: 'Training', params: {trainingId: renderTrainingId}}">
                        <span class="input-group-text" id="basic-addon1">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-door-fill" viewBox="0 0 16 16">
                            <path d="M6.5 14.5v-3.505c0-.245.25-.495.5-.495h2c.25 0 .5.25.5.5v3.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5z"/>
                          </svg>
                        </span>
                        <small>Koulutuksen etusivulle</small>
          </router-link>-->
          <br />
          <br />
        </div>
      </template>
    </template>
    <div class="flexCenter loaderPadding">
      <Loading :loading="loading" />
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount, computed, watch, onActivated } from 'vue'
import { useRoute } from "vue-router";
import TrainingHandler from './TrainingHandler'
import { showCourseResult, renderTrainingId, renderCourse, renderCourseState, renderCourseStructure, renderShowResult, resetState } from './TrainingHandlerProgress'

import SwegonLeaf from "@components/SwegonLeaf"
import Loading from 'Loading'
import TrainingHeader from './TrainingHeader'
import Training from './Training'
import Result from './result/Result'
import TrainingProgressList from './TrainingProgressList'
import HomeButton from '@components/HomeButton'
import DemoModeToggle from './DemoModeToggle'
import { AnswerHandler, UserApi, TrainingEnums } from '@moods/taitaen-core';

export default {
  name: 'TrainingExecution',
  components: {
    Loading,
    TrainingHeader,
    Training,
    Result,
    TrainingProgressList,
    HomeButton,
    SwegonLeaf,
    DemoModeToggle
  },
  setup() {
    const route = useRoute();
    const animationState = ref(false)
    const isVisible = ref(false);

    //const { showResult, currentTrainingId, currentCourse, currentCourseStructure } = getTrainingProgress();
    const loading = ref(true);

    // const currentTrainingId = ref('');
    // const currentCourse = ref(null);
    // const currentCourseStructure = ref(null);
    // const showResult = ref(false);


    onBeforeMount(() => start())
    onActivated(() => start())

    function start() {
      TrainingHandler.initTrainingInstance();

      // Reset states
      const { eraseAllAnswers } = AnswerHandler()
      eraseAllAnswers();
      resetState();
      // Get and set data
      TrainingHandler.trainingInstance.setup(route.params.trainingId, route.params.courseId)
        .then(() => {
          const { training, course, courseStructure, courseState, showResult } = TrainingHandler.trainingInstance.get()

          //showCourseResult.value = false;
          renderTrainingId.value = training.getId(); // HUOM! PÄIVITÄ STATE
          renderCourse.value = course.get(); // HUOM! PÄIVITÄ STATE
          renderCourseState.value = courseState.get();
          renderShowResult.value = showResult.get();
          checkShowCourseResult();

          renderCourseStructure.value = courseStructure.get(); // HUOM! PÄIVITÄ STATE

          loading.value = false;
        })
        .catch(() => {

        })
    }


    function checkShowCourseResult() {
      const { ProgressState } = TrainingEnums;
      if (renderCourseState.value.courseState.state === ProgressState.PASSED) {
        showCourseResult.value = true;
      }
    }

    watch(() => renderCourseStructure.value, stateChanged);
    watch(() => renderShowResult.value, stateChanged);
    watch(() => showCourseResult.value, stateChanged);

    function stateChanged() {
      isVisible.value = false
      setTimeout(() => {
        animationState.value = !animationState.value;
      });
    }

    function afterLeave() {
      setTimeout(() => {
        animationState.value = true;
      });
    }
    function enter() {
      isVisible.value = true
    }

    return {
      route,
      loading: computed(() => loading.value),
      isVisible: computed(() => isVisible.value),
      isTestModeAllowed: UserApi.isTestModeAllowed(),
      isTestModeEnabled: UserApi.isTestModeEnabled(),

      renderShowResult: computed(() => renderShowResult.value),

      renderTrainingId: computed(() => renderTrainingId.value),
      renderCourse: computed(() => renderCourse.value),
      //currentTrainingId: training.getId(),
      //currentCourse: course,


      animationState,
      afterLeave,
      enter,
    }
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active {
  animation: fade-in 0.2s;
}
.fade-leave-active {
  animation: fade-in 0.2s reverse;
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
