import { ref, reactive, isRef, isReactive } from 'vue'

const allAnswers = ref({})

export function useAnswer() {
  function setAnswers(answers, data) {
    if (isRef(answers)) {
      answers.value = data;
    } else if (isReactive(answers)) {
      answers = data;
    }
  }

  function eraseAllAnswers() {
    allAnswers.value = {};
  }

  function eraseAnswerByTaskId(task_id) {
    delete allAnswers.value[task_id];
  }

  /**
   * Get answers
   * 
   * @param {*} course_id
   * 
   * @return array
   */
  function getAnswersInArray() {
    let taskId, results = [];
    for (taskId in allAnswers.value) {
      results.push({
        taskId: taskId,
        answer: allAnswers.value[taskId],
      })
    }
    return results;
  }

  /**
   * Get answers
   * 
   * @param {*} course_id
   * 
   * @return array
   */
  function getAnswersWithCourseId(course_id) {
    let taskId, results = [];
    for (taskId in allAnswers.value) {
      results.push({
        courseId: course_id,
        taskId: taskId,
        answer: allAnswers.value[taskId],
      })
    }
    return results;
  }

  function doesTaskExist(task_id) {
    if (allAnswers.value[task_id]) {
      return true;
    }
    return false;
  }

  function createRefAnswer(task_id, data) {
    if (allAnswers.value[task_id]) {
      // setWatcher(allAnswers.value[task_id])
      return ref(allAnswers.value[task_id]);
    }
    const taskAnswers = ref(data);
    allAnswers.value[task_id] = ref(taskAnswers)
    // setWatcher(taskAnswers);
    return taskAnswers;
  }

  // function setWatcher(variable) {
  //   watch(() => variable, (newValue, oldValue) => {
  //     console.log('TASK_ANSWERS', newValue, oldValue)
  //   }, { deep: true })
  // }

  function createReactiveAnswer(task_id, data) {
    const taskAnswers = reactive(data);
    if (!allAnswers.value[task_id]) {
      allAnswers.value[task_id] = taskAnswers
    }
    return taskAnswers;
  }

  return {
    createRefAnswer,
    createReactiveAnswer,
    setAnswers,
    doesTaskExist,
    eraseAllAnswers,
    eraseAnswerByTaskId,
    getAnswersInArray,
    getAnswersWithCourseId,
    allAnswers
  }
}