<template>
  <div>
    <VideoContainer
      v-if="data && data.videoType"
      :courseStructure="courseStructure"
      :data="data"
      :isTest="(isTest !== 'undefined') ? isTest : false"
    />
    <PanoramaContainer
      v-if="data && data.containerType && data.containerType === 'panorama'"
      :courseStructure="courseStructure"
      :data="data"
      :isTest="(isTest !== 'undefined') ? isTest : false"
    />
  </div>
</template>

<script>
import VideoContainer from './containers/Video'
import PanoramaContainer from './containers/Panorama'

import { getJson } from '@utils/General'

export default {
  name: 'HandleContainer',
  props: ['courseStructure', 'isTest'],
  components: {
    VideoContainer,
    PanoramaContainer
  },
  setup(props) {
    const data = getJson(props.courseStructure.structureRefData.data);

    return {
      data
    }
  }
}
</script>