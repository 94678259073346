<template>
  <button class="button button3" @click="restart();" :disabled="loading">Aloita kurssi alusta</button>
</template>

<script>
import { resetInternalState, updateRenderStateByCoreState } from './TrainingHandlerProgress'
import TrainingHandler from './TrainingHandler'
import { computed, ref } from 'vue'

export default {
  setup() {
    const loading = ref(false);
    const { trainingAPI } = TrainingHandler.trainingInstance.get()

    function restart() {
      let text = "Haluatko varmasti aloittaa kurssin uudelleen?";
      if (!confirm(text) === true) {
        return;
      }
      
      loading.value = true;
      resetInternalState();

      trainingAPI.restart(true)
        .then(() => {
          updateRenderStateByCoreState()
          loading.value = false;
        })
    }

    return {
      restart,
      loading: computed(() => loading.value)
    }
  }
}
</script>
