const FullscreenAPI = {
  isFullscreenEnabled() {
    return document.fullscreenEnabled || document.msFullscreenEnabled || document.mozFullscreenEnabled || document.webkitFullscreenEnabled ? true : false;
  },

  isFullscreenOn() {
    return document.fullscreenElement || document.msFullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement ? true : false;
  },

  openElement(htmlElement) {
    if (this.isFullscreenOn()) {
      return false;
    }

    if (htmlElement.requestFullscreen) {
      return htmlElement.requestFullscreen();
    } else if (htmlElement.msRequestFullscreen) {
      return htmlElement.msRequestFullscreen();
    } else if (htmlElement.mozRequestFullScreen) {
      return htmlElement.mozRequestFullScreen();
    } else if (htmlElement.webkitRequestFullscreen) {
      return htmlElement.webkitRequestFullscreen();
    } else if (htmlElement.webkitEnterFullScreen) {
      return htmlElement.webkitEnterFullScreen();
    }

    return null;
  },

  close() {
    if (!this.isFullscreenOn()) {
      return;
    }

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.webkitExitFullScreen) {
      document.webkitExitFullScreen();
    }
  }
}
export default FullscreenAPI