<template>
  <HomeButton :to="{name: 'Training', params: {trainingId: route.params.trainingId}}" />
</template>

<script>
import HomeButton from "@components/HomeButton"
import { useRoute } from 'vue-router'
export default {
  name: "TrainingHomeButton",
  components: {
    HomeButton
  },
  setup() {
    const route = useRoute();

    return {
      route,
    }
  },
}
</script>
