<template>
  <div>
    Rekisteröityminen onnistui! <button class="button" @click="redirect">Kirjaudu sisään täältä</button>
  </div>
</template>

<script>
import router from "@src/router";

export default {
  setup() {
    function redirect() {
      router.push({ name: "Login" }).catch((err) => console.error("virhe ohjauksessa", err));
    }

    return {
      redirect
    }
  }
}
</script>