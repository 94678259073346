<template>
  <div>
    <h1>{{ courseStructure.structureRefData.content.header }}</h1>
    <!--
    <component
      :is="taskComponent"
      :taskId="1"
      :taskType="data.type"
      :testData="data.data"
      key="testTask">
    </component>
    -->
    <HandleCourseStructure :courseStructure="courseStructure" :isTest="true" />
  </div>
</template>

<script>
import { onBeforeMount, computed, ref, onBeforeUnmount } from "vue";
import { AnswerHandler } from "@moods/taitaen-core";

import TaskGalleryData from "./TaskGalleryData";

import HandleCourseStructure from "../training-execution/HandleCourseStructure";

export default {
  name: "TaskGalleryItem",
  components: {
    HandleCourseStructure,
  },
  props: ["taskSlug"],
  setup(props) {
    const courseStructure = ref(null);

    const { eraseAllAnswers } = AnswerHandler();

    onBeforeMount(() => {
      eraseAllAnswers();
      setCourseStructure();
    });

    onBeforeUnmount(() => {
      eraseAllAnswers();
    });

    function setCourseStructure() {
      courseStructure.value = TaskGalleryData.taskCourseStructures[props.taskSlug];
    }

    return {
      courseStructure: computed(() => courseStructure.value),
    };
  },
};
</script>

<style lang="scss" scoped>
.button.backButton {
  margin-top: 40px;
}
</style>
