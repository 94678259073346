<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <div v-if="loading" class="flexCenter loaderPadding"><Loading :loading="loading" /></div>
          <div v-if="!loading">
            <h2>Keskustelukanavat</h2>
            <div class="container-fluid posRelative fullback" v-for="(messageBoard, index) in messageBoards" :key="index">
              <router-link :to="{ name: 'MessageBoard', params: { messageboardId: messageBoard.id } }">
                {{ messageBoard.header }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import Loading from "Loading";
import { Store } from "@moods/taitaen-core";

export default {
  name: "MessageBoards",
  components: {
    Loading,
  },
  setup() {
    const loading = ref(false);
    const messageBoards = ref([]);

    onBeforeMount(() => {
      loading.value = true;
      getMessageBoards();
    });

    function getMessageBoards() {
      Store.dispatch("MessageBoards/getMessageBoards").then(
        (s) => {
          messageBoards.value = s.data;
          loading.value = false;
        },
        (e) => {
          console.error("Something failed: ", e);
          loading.value = false;
        }
      );
    }

    return {
      loading,
      messageBoards,
    };
  },
};
</script>