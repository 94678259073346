<template>
  <div>
    <div class="iframeContainer">
      <div ref="iframeOverlay" class="videoWrapper"></div>
      <div :id="'video' + id"></div>
    </div>
    <slot name="toolbar"></slot>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, watch } from "vue";
import VimeoPlayer from "@vimeo/player";

export default {
  name: "VideoVimeo",
  props: [
    "options",
    "id",
    "videoId",
    "data",
    "play",
    "fullscreen",
    "dynamicOptions", // Variables
    "player",
    "stateChanged",
    "isPaused", // Events
  ],
  setup(props, { emit }) {
    var defaultOptions = {
      id: props.videoId,
      controls: false,
      width: 640,
    };

    var player = null;
    const iframeOverlay = ref(null);
    var options = {};
    var currentIndex = 0;
    var isVideoStarted = false;

    watch(
      () => props.play,
      () => {
        if (props.play) {
          player.play();
        } else {
          player.pause();
        }
      }
    );

    watch(
      () => props.fullscreen,
      (newValue) => {
        if (newValue) {
          openFullscreen();
        }
      }
    );

    onMounted(() => {
      setOptions();
      player = new VimeoPlayer("video" + props.id, options);

      setEventListeners();

      emit("player", player);

      iframeOverlay.value.addEventListener("click", click);
    });

    function setEventListeners() {
      let events = ["play", "playing", "pause", "ended", "fullscreenchange"];

      for (let i in events) {
        player.on(events[i], function () {
          if (events[i] === "play" && !isVideoStarted) {
            isVideoStarted = true;
            emit("state-changed", {
              videoStarted: isVideoStarted,
            });
          }
          emit("state-changed", events[i]);
        });
      }

      player.on("timeupdate", function (data) {
        if (props.data[currentIndex]) {
          if (data.seconds >= props.data[currentIndex].stopTimeSeconds) {
            player.pause();
            emit("is-paused", props.data[currentIndex].courseStructureId);
            currentIndex++;
          }
        }
      });
    }

    function openFullscreen() {
      player
        .requestFullscreen()
        .then(function () {
          // the player entered fullscreen
        })
        .catch(function () {
          // an error occurred
        });
    }

    // function closeFullscreen() {
    //   player.exitFullscreen().then(function () {
    //     // the player exits fullscreen
    //   }).catch(function () {
    //     // an error occurred
    //   });
    // }

    function setOptions() {
      if (props.options) {
        options = props.options;
      } else {
        options = defaultOptions;
      }
      if (!options.id && props.videoId) {
        options.id = props.videoId;
      }
    }

    function click() {
      player
        .getPaused()
        .then(
          function (paused) {
            if (paused) {
              player.play();
            } else {
              player.pause();
            }
          }.bind(this)
        )
        .catch(function () {
          // an error occurred
        });
    }

    onBeforeUnmount(() => {
      if (iframeOverlay.value) {
        iframeOverlay.value.removeEventListener("click", click);
      }
    });

    return {
      iframeOverlay,
    };
  },
};
</script>
