<template>
  <main id="main">
    <!--
        <router-view v-slot="{ Component }">
            <transition
                name="fade"
                mode="out-in"
                @beforeLeave="beforeLeave"
                @enter="enter"
                @afterEnter="afterEnter"
            >
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </transition>
        </router-view>
        -->
    <FeedbackForm v-if="isFeedbackFormOpened" />

    <template v-if="!isCriticalError">
      <router-view :key="$route.fullPath"></router-view>
    </template>
    <CriticalError v-else/>
  </main>
</template>

<script>
//import Loading from 'Loading'
import { Store } from "@moods/taitaen-core";
import { ref, computed } from "vue";
import FeedbackForm from "@components/FeedbackForm"
import CriticalError from "@components/CriticalError"
import useFeedbackForm from "@hooks/useFeedbackForm"

export default {
  components: {
    CriticalError,
    FeedbackForm,
  },
  setup() {
    const isCriticalError = ref(Store.getter("App/getCriticalError"));

    const { isFeedbackFormOpened } = useFeedbackForm;

    Store.subscribe("App/criticalError", (newValue) => {
      if (newValue) {
        isCriticalError.value = newValue;
        Store.commit("App/resetCriticalError");
      }
    });

    const prevHeight = ref(0);

    function beforeLeave(element) {
      prevHeight.value = getComputedStyle(element).height;
    }
    function enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = prevHeight.value;

      setTimeout(() => {
        element.style.height = height;
      });
    }
    function afterEnter(element) {
      element.style.height = "auto";
    }

    return {
      isCriticalError: computed(() => isCriticalError.value),
      prevHeight: computed(() => prevHeight.value),
      beforeLeave,
      enter,
      afterEnter,
      isFeedbackFormOpened
    };
  },
  /*
    components: {
        Loading,
    },
    data() {
        return {
            loading: this.$store.getters['app/getLoading'],
        }
    },
    */
};
</script>

<style lang="scss">
/*
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}
*/
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.18s;
  transition-property: height, opacity;
  transition-timing-function: ease;
  overflow: hidden;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/*
main {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 120px;
}
*/
</style>
