import FullscreenAPI from './FullscreenAPI'

/**
 * Is element overlapping another
 *
 * @param {DOM Element} el1
 * @param {DOM Element} el2
 *
 * @return bool
 */
export function isElementOverlapping(el1, el2) {
  var el1_bottom = el1.offsetTop + el1.clientHeight;
  var el1_right = el1.offsetLeft + el1.clientWidth;

  var el2_bottom = el2.offsetTop + el2.clientHeight;
  var el2_right = el2.offsetLeft + el2.clientWidth;

  return !(
    el1_bottom < el2.offsetTop ||
    el1.offsetTop > el2_bottom ||
    el1_right < el2.offsetLeft ||
    el1.offsetLeft > el2_right
  );
}

/**
 * Is pseudo-element overlapping another
 *
 * @param object obj1
 * @param object obj2
 *
 * @return bool
 */
export function isObjectOverlapping(obj1, obj2) {
  /**
   * Object structure
   * {
   *     width: x,
   *     height: x,
   *     top: x,
   *     left: x,
   * }
   */

  var obj1_bottom = obj1.top + obj1.height;
  var obj1_right = obj1.left + obj1.width;

  var obj2_bottom = obj2.top + obj2.height;
  var obj2_right = obj2.left + obj2.width;

  return !(
    obj1_bottom < obj2.top ||
    obj1.top > obj2_bottom ||
    obj1_right < obj2.left ||
    obj1.left > obj2_right
  );
}

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

/**
 * Get random int
 *
 * @param int max
 *
 * @return int
 */
export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
  //return Math.floor(Math.random() * max);
}

/**
 * Checks is variable not empty
 *
 * @param {*} variable
 */
export function isNotEmpty(variable) {
  if (variable === null || typeof variable === 'undefined') {
    return false;
  } else if (typeof variable === 'object' && Object.keys(variable).length > 0) {
    return true;
  } else if (typeof variable !== 'object' && variable) {
    return true;
  }
  return false;
}

export function isEmpty(variable) {
  if (variable === null || typeof variable === 'undefined') {
    return true;
  } else if (typeof variable === 'object' && Object.keys(variable).length === 0) {
    return true;
  }
  return false;
}

export function getObjectByKey(obj, key) {
  let result = {};
  for (let i in obj) {
    result[obj[i][key]] = obj[i];
  }
  return result;
}

export function arrayToObject(array, key) {
  let obj = {};
  for (let i = 0; i < array.length; i++) {
    obj[array[i][key]] = array[i]
  }
  return obj;
}

export function resetElementsHeights(elements) {
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.height = null;
  }
}

export function shuffleArray(array) {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export function getDeepCopy(data) {
  return JSON.parse(JSON.stringify(data));
}

export function setHtmlElementToFullscreen(htmlElement) {
  FullscreenAPI.openElement(htmlElement);
}

export function closeFullscreen() {
  FullscreenAPI.close();
}

// export function closeFullScreenFromHtmlElement(htmlElement) {
//     if (htmlElement.exitFullscreen) {
//         htmlElement.exitFullscreen();
//     } else if (document.msExitFullscreen) {
//         htmlElement.msExitFullscreen();
//     } else if (htmlElement.mozCancelFullScreen) {
//         htmlElement.mozCancelFullScreen();
//     } else if (htmlElement.webkitExitFullscreen) {
//         htmlElement.webkitExitFullscreen();
//     }
// }

export function setElementsHeightsAsEqual(elements) {
  let height = 0, i;

  // Set heights as null
  resetElementsHeights(elements);

  // Get highest height
  for (i = 0; i < elements.length; i++) {
    if (height < elements[i].offsetHeight) {
      height = elements[i].offsetHeight;
    }
  }

  // Set highest height to all elements
  for (i = 0; i < elements.length; i++) {
    elements[i].style.height = height + 'px';
  }
}

export function animate({ duration, draw, timing }) {
  let start = performance.now();

  requestAnimationFrame(function animate(time) {
    let timeFraction = (time - start) / duration;
    if (timeFraction > 1) timeFraction = 1;

    let progress = timing(timeFraction)

    draw(progress);

    if (timeFraction < 1) {
      requestAnimationFrame(animate);
    }
  });
}

export function getJson(data) {
  return (typeof data === 'string') ? JSON.parse(data) : data;
}

export function isValidJson(data) {
  try {
    return JSON.parse(data) ? true : false;
  } catch (e) {
    return false;
  }
}

export function getJsonIfValid(data) {
  try {
    const result = JSON.parse(data);
    return result;
  } catch (e) {
    return false;
  }
}

export function getConfContentValue(confContent, key) {
  confContent = getJson(confContent);
  if (!confContent) {
    return null;
  }
  const obj = confContent.find(({ id }) => id === key);
  if (obj) {
    return obj.value;
  }
  return null;
}