<template>
  <div id="login" ref="loginRef">
    <SwegonLeaf/>

    <div ref="loginContentRef" class="container contentPadding">
      <div class="row">
        <div id="loginContent" class="col">
          <img id="loginLogo" :src="tenantData.tenantLogo" alt="Logo" />
          <p>
            Kirjaudu sisään Taitaen-alustaan. Jos sinulla ei ole tunnuksia, käythän <router-link :to="{ name: 'Signup' }">rekisteröitymässä</router-link> ensin.
          </p>
          <br />
          <form action="#" @submit.prevent="login">
            <div class="onSameLine">
              <input id="username" type="text" name="username" placeholder="Käyttäjätunnus" v-model="username" />
              <input id="password" type="password" name="password" placeholder="Salasana" v-model="password" />
              <div id="rememberMe">
                <label>
                  <input type="checkbox" name="rememberMe" value="1" v-model="rememberMe" /> Muista minut
                </label>
              </div>
              <input class="button" type="submit" value="Kirjaudu" />
            </div>

            <Loading :loading="loading" />
            <template v-if="!loading"><Messages :isSuccess="false" :messages="errors" /></template>

            <!--<router-link class="raquo" :to="{name: 'ForgotPassword'}"><strong>Unohtuiko salasana?</strong></router-link>-->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import router from "@src/router";
import Loading from "Loading";
import Messages from "Messages";
import * as TaitaenCore from "@moods/taitaen-core";
import { useResize } from "@hooks/useResize";
import { useRoute } from 'vue-router'
import tenantData from "@src/tenantData";
import SwegonLeaf from "@components/SwegonLeaf"


//import '@moods/taitaen-vue-tasks/dist/style.css';

export default {
  name: "Login",
  components: {
    Loading,
    Messages,
    SwegonLeaf
  },
  setup() {
    const route = useRoute();
    const { Store, AppConfig } = TaitaenCore;
    const { set } = useResize();
    const loginRef = ref(null);
    const loginContentRef = ref(null);
    set(loginRef, loginContentRef);

    const username = ref("");
    const password = ref("");
    const rememberMe = ref(0);
    const loading = ref(false);
    const errors = ref({});

    onMounted(async() => {
      getExternalToken();
    });

    function login() {
      //store.commit('user/setRememberMe', rememberMe.value);

      // Get token
      loading.value = true;
      errors.value = {};

      // store.dispatch('user/getToken', {
      //     username: username.value,
      //     password: password.value,
      //     remember_me: rememberMe.value,
      // })
      //Request.User.actions.getToken({
      Store.dispatch("User/getToken", {
        username: username.value,
        password: password.value,
        rememberMe: rememberMe.value,
      })
        .then((res) => {
          loading.value = false;
          if (res.data.errors) {
            errors.value = res.data.errors;
          } else {
            //router.push('/')
            router.push({ name: "Home" }).catch((err) => console.error("virhe ohjauksessa", err));
          }
        })
        .catch((err) => {
          loading.value = false;
          errors.value = "Kirjautuminen epäonnistui";

          if (err.response.data && err.response.data.errors) {
            errors.value = err.response.data.errors;
          }
        });
    }

    function externalLogin(method) {
      // build url
      let url = `https://localhost:5200/authentication/${method}?apikey=${process.env.VUE_APP_TENANT_KEY}&returnUrl=${window.location.origin}${window.location.pathname}`;
      window.location = url;
    }

    function getExternalToken() {
      if (route.query.accessToken === undefined || route.query.accessToken.length < 5) {
        return;
      }
      loading.value = true;

      Store.dispatch("User/verifyExternalToken", route.query.accessToken)
        .then((res) => {
          loading.value = false;

          if (res.data.errors) {
            errors.value = res.data.errors;
          } else {
            router.push({ name: "Home" }).catch((err) => console.error("virhe ohjauksessa", err));
          }
        })
        .catch((err) => {
          loading.value = false;
          errors.value = "Kirjautuminen epäonnistui";
          if (err.response.data && err.response.data.errors) {
            errors.value = err.response.data.errors;
          }
        });
    }

    return {
      loginRef,
      loginContentRef,

      username,
      password,
      loading,
      errors,
      rememberMe,
      login,
      externalLogin,

      environmentMode: AppConfig.get().environmentMode,
      tenantData,
      //tenantLogo: tenantData.tenantLogo
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";
#loginLogo {
  width: 400px;
  max-width: 100%;
}

.onSameLine {
  display: flex;
  justify-content: center;
  align-items: center;

  > * {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    margin-top: 0;
  }

  label {
    margin-bottom: 0;
  }
}

#login {
  position: relative;

  h1 {
    font-weight: bold;
  }

  input {
    &[type="text"],
    &[type="password"] {
      padding-top: 8px;
      padding-bottom: 8px;
      background-color: #e3e3e3;
      display: block;
      width: 200px;
      //width: 100%;
      //max-width: 265px;
    }
    /*
        padding: 5px;
        border: 1px solid #eee;
        margin-bottom: 10px;
        */

    &[type="submit"] {
      border: 0;
    }
  }
}
#loginLogo {
  margin-bottom: 20px;
}
#loginContent {
  position: relative;
  max-width: 800px;
  margin-top: 80px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
@media screen and (min-width: $mdMinWidth) {
  #loginContent {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: $mdMaxWidth) {
  #loginContent {
    margin-top: 0px;
    padding-bottom: 150px;
  }
  #rememberMe {
    margin-top: 10px;
  }
  .onSameLine {
    flex-direction: column;
    justify-content: left;
  }
}
</style>
