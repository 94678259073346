<template>
    <div id="training" ref="trainingRef">
        <template v-if="!loading">
            <HandleCourseStructure :courseStructure="renderCourseStructure" />
            <div id="buttons">
                <div class="trainingProgressButtons">
                    <button v-if="hasPreviousCourseStructure" type="button" class="button prevButton raquoBefore" @click="previous(isLoading); setLoading(true)" :disabled="loading">Edellinen</button>
                    <button type="button" class="button raquoAfter" @click="next(isLoading); setLoading(true)" :disabled="loading">Seuraava</button>
                </div>
                <br>
                <RestartCourse/><br/><br/>
                <TrainingHomeButton />
                <!-- <router-link class="raquoBefore" :to="{name: 'Training', params: {trainingId: renderTrainingId}}">
                  <small>Koulutuksen etusivulle</small>
                </router-link> -->
            </div>
        </template>
        <Loading v-else :loading="loading"/>
    </div>
</template>

<script>
import { watch, ref, computed, onBeforeMount } from 'vue'
import { StructureTypes, TaskTypes } from '@utils/Training'
import Loading from 'Loading'
import { useRoute } from 'vue-router'

import TrainingHandler from './TrainingHandler'
import { renderTrainingId, renderCourseStructure, next, previous } from './TrainingHandlerProgress'

import HandleCourseStructure from './HandleCourseStructure'

import { AppConfig } from "@moods/taitaen-core";
import RestartCourse from './RestartCourse'
import TrainingHomeButton from './TrainingHomeButton'


export default {
    components: {
        TrainingHomeButton,
        Loading,
        HandleCourseStructure,
        RestartCourse
    },
    setup() {
        const { course } = TrainingHandler.trainingInstance.get();
        const route = useRoute();

        const trainingRef = ref(null);
        const _hasPreviousCourseStructure = ref(false);
        const _hasNextCourseStructure = ref(false);
        const loading = ref(false);

        watch(() => renderCourseStructure.value, currentCourseStructureChanged);
        onBeforeMount(() => {
            currentCourseStructureChanged();
        })

        function currentCourseStructureChanged() {
            loading.value = false;
            _hasPreviousCourseStructure.value = course.hasPreviousCourseStructure();
            _hasNextCourseStructure.value = course.hasNextCourseStructure();
        }

        function setLoading(data) {
            loading.value = data
        }
        
        return {
            // Enums
            StructureTypes,
            TaskTypes,

            route,
            environmentMode: AppConfig.get().environmentMode,

            renderTrainingId: computed(() => renderTrainingId.value),
            renderCourseStructure: computed(() => renderCourseStructure.value),
            loading: computed(() => loading.value),
            isLoading: loading,
            hasPreviousCourseStructure: computed(() => _hasPreviousCourseStructure.value),
            hasNextCourseStructure: computed(() => _hasNextCourseStructure.value),

            trainingRef,
            setLoading,
            next,
            previous,
        }
    },
};
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

.trainingProgressButtons {
    .button {
        white-space: nowrap;
    }
}
@media only screen and (min-width: $smMinWidth) {
    .trainingProgressButtons {
        display: block;

        .prevButton {
            margin-right: 20px;
        }
    }
}
@media only screen and (max-width: $smMaxWidth) {
    .trainingProgressButtons {
        display: flex;
        flex-wrap: wrap;

        .prevButton {
            border-right: 1px solid var(--theme-color-second);
        }
        .button {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: $smMaxWidth) {
    .button {
        width: 100%;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

#buttons {
    margin-top: 30px;
}

.fade-enter-active {
  animation: fade-in 0.2s;
}
.fade-leave-active {
  animation: fade-in 0.2s reverse;
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.bounce-enter-active {
  animation: bounce-in 0.5s;
}
.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

</style>
