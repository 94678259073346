import { useAnswer } from '../useAnswer'

const { createRefAnswer } = useAnswer();

export default function useContainer() {
  function initAnswers(childrenCourseStructures) {
    for (let i in childrenCourseStructures) {
      createRefAnswer(childrenCourseStructures[i].structureTypeId, null);
    }
  }

  function getChildrenCourseStructures(props) {
    if (props.courseStructure.children) {
      return props.courseStructure.children;
    }
    if (props.isTest && props.data.courseStructures) {
      return props.data.courseStructures;
    }
    return null;
  }

  return {
    getChildrenCourseStructures,
    initAnswers
  }
}