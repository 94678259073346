<template>
  <div>
    <ProgressList
      v-if="renderCourseState.courseState"
      :course="renderCourse"
      :courseStructure="renderCourseStructure"
      :courseState="renderCourseState"
    />
  </div>
</template>

<script>
import { computed, } from 'vue'

import TrainingHandler from './TrainingHandler'
import ProgressList from '@tools/ProgressList'
import { renderCourse, renderCourseStructure, renderCourseState } from './TrainingHandlerProgress'

export default {
  name: 'TrainingProgressList',
  components: {
    ProgressList
  },
  setup() {
    const { courseState } = TrainingHandler.trainingInstance.get();

    renderCourseState.value = courseState.get()

    return {
      renderCourse: computed(() => renderCourse.value),
      renderCourseStructure: computed(() => renderCourseStructure.value),
      renderCourseState: computed(() => renderCourseState.value),
    }
  }
}
</script>
