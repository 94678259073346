<template>
  <div class="row">
    <template :key="columnIdx" v-for="(column, columnIdx) in row.columns">
      <TemplateColumn :column="column" />
    </template>
  </div>
</template>

<script>
import TemplateColumn from "./TemplateColumn.vue";

export default {
  name: "TemplateRow",
  components: {
    TemplateColumn,
  },
  props: ["row"],
};
</script>