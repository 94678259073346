<template>
  <div class="col-12 col-md" :style="{ width: column.width }">
    <template v-if="column.content.contentType === 'TextRow'">{{ column.content.content }}</template>
    <template v-else-if="column.content.contentType === 'Image'">
      <div class="fullBg" :style="{ paddingBottom: '60%', backgroundImage: 'url(https://cdn.taitaen.fi/demo/ensiapu_toimintaohjeet.jpg)' }"></div>
      <!-- <div class="bg" :style="{backgroundImage: 'url('+column.content.content+')'}"></div> -->
    </template>
    <template v-else-if="column.content.contentType === 'Html'">
      <div v-html="column.content.content" />
    </template>
  </div>
</template>

<script>
export default {
  name: "TemplateColumn",
  props: ["column"],
};
</script>
