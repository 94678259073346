<template>
  <canvas ref="chartRef" :id="id" style="width: 100%"></canvas>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from "vue";
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.defaults.global.defaultFontFamily = "'Swegon Brown', Arial, sans-serif";

export default {
  name: "ProgressChart",
  props: ["id", "data", "datasetColors", "isRerendered"],
  setup(props) {
    var myChart = null;
    //var ctx = null;

    const chartRef = ref(null);

    onMounted(() => {
      if (!chartRef.value) {
        return;
      }
      //var randomScalingFactor = function() {
      //	return Math.round(Math.random() * 100);
      //};
      myChart = new Chart(chartRef.value, getConfig());
      //registerPluginService();
      //const myChart = new Chart(ctx, config);
    });

    onBeforeUnmount(() => {
      if (myChart) {
        myChart.destroy();
        myChart = null;
      }
    });

    function getConfig() {
      let animation = null;
      if (!props.isRerendered) {
        animation = {
          //animateScale: true,
          animateRotate: true,
        };
      }

      return {
        type: "doughnut",
        plugins: [ChartDataLabels],
        data: {
          datasets: [
            {
              data: props.data, // Example value: [40, 10, 50]
              backgroundColor: props.datasetColors,
              // backgroundColor: [
              //     '#11b800',
              //     '#fe7202',
              //     '#ffffff',
              // ],
              //label: 'Dataset 1'
              //hover: false,
            },
          ],
          labels: ["Hyväksytty", "Kesken", "Aloittamaton"],
        },
        options: {
          responsive: true,

          legend: {
            display: false,
          },

          hover: { mode: null },

          tooltips: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                let value = data.datasets[0].data[tooltipItem.index] || "";
                let label = data.labels[tooltipItem.index] || "";
                return label + ": " + parseFloat(value * 100, 1).toFixed(1) + " %";
              },
            },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                ctx.chart.config.data.labels[ctx.dataIndex];
                let val = value || "";
                if (!val) {
                  return "";
                }
                return parseFloat(val * 100, 1).toFixed(1) + " %";
                //return label+': '+parseFloat(val*100, 1).toFixed(1)+' %';
              },
              color: "#000",
            },
          },

          /*
                    title: {
                        display: true,
                        text: 'Chart.js Doughnut Chart'
                    },
                    */
          animation: animation,
          elements: {
            arc: {
              borderWidth: 0,
            },
            center: {
              text: "SUORITUSMITTARI",
              color: "#fff",
              //color: '#FF6384', // Default is #000000
              fontStyle: "'Swegon Brown', Arial, sans-serif",
              //fontStyle: 'Arial', // Default is Arial
              sidePadding: 20, // Default is 20 (as a percentage)
              minFontSize: 16, // Default is 20 (in px), set to false and text will not wrap.
              lineHeight: 25, // Default is 25 (in px), used for when text wraps
            },
          },
          cutoutPercentage: 60,
          //circumference: 2 * Math.PI,
          //rotation: -Math.PI / 2,
          circumference: Math.PI * 1.5,
          rotation: -Math.PI * 1.25,
        },
      };
    }

    // function registerPluginService() {
    //     Chart.pluginService.register({
    //         beforeDraw: function(chart) {
    //             if (chart.config.options.elements.center) {
    //                 // Get ctx from string
    //                 var ctx = chart.chart.ctx;

    //                 // Get options from the center object in options
    //                 var centerConfig = chart.config.options.elements.center;
    //                 var fontStyle = centerConfig.fontStyle || 'Arial';
    //                 var txt = centerConfig.text;
    //                 var color = centerConfig.color || '#000';
    //                 var maxFontSize = centerConfig.maxFontSize || 75;
    //                 var sidePadding = centerConfig.sidePadding || 20;
    //                 var sidePaddingCalculated = (sidePadding / 100) * (chart.innerRadius * 2)
    //                 // Start with a base font of 30px
    //                 ctx.font = "30px " + fontStyle;

    //                 // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
    //                 var stringWidth = ctx.measureText(txt).width;
    //                 var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

    //                 // Find out how much the font can grow in width.
    //                 var widthRatio = elementWidth / stringWidth;
    //                 var newFontSize = Math.floor(30 * widthRatio);
    //                 var elementHeight = (chart.innerRadius * 2);

    //                 // Pick a new font size so it will not be larger than the height of label.
    //                 var fontSizeToUse = Math.min(newFontSize, elementHeight, maxFontSize);
    //                 var minFontSize = centerConfig.minFontSize;
    //                 var lineHeight = centerConfig.lineHeight || 25;
    //                 var wrapText = false;

    //                 if (minFontSize === undefined) {
    //                     minFontSize = 20;
    //                 }

    //                 if (minFontSize && fontSizeToUse < minFontSize) {
    //                     fontSizeToUse = minFontSize;
    //                     wrapText = true;
    //                 }

    //                 // Set font settings to draw it correctly.
    //                 ctx.textAlign = 'center';
    //                 ctx.textBaseline = 'middle';
    //                 var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
    //                 var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
    //                 ctx.font = fontSizeToUse + "px " + fontStyle;
    //                 ctx.fillStyle = color;

    //                 if (!wrapText) {
    //                     ctx.fillText(txt, centerX, centerY);
    //                     return;
    //                 }

    //                 var words = txt.split(' ');
    //                 var line = '';
    //                 var lines = [];

    //                 // Break words up into multiple lines if necessary
    //                 for (var n = 0; n < words.length; n++) {
    //                     var testLine = line + words[n] + ' ';
    //                     var metrics = ctx.measureText(testLine);
    //                     var testWidth = metrics.width;
    //                     if (testWidth > elementWidth && n > 0) {
    //                     lines.push(line);
    //                         line = words[n] + ' ';
    //                     } else {
    //                         line = testLine;
    //                     }
    //                 }

    //                 // Move the center up depending on line height and number of lines
    //                 centerY -= (lines.length / 2) * lineHeight;

    //                 for (var n = 0; n < lines.length; n++) {
    //                     ctx.fillText(lines[n], centerX, centerY);
    //                     centerY += lineHeight;
    //                 }
    //                 //Draw text in center
    //                 ctx.fillText(line, centerX, centerY);
    //             }
    //         }
    //     });
    // }

    return {
      chartRef,
    };
  },
};
</script>
