<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <div v-if="loading" class="flexCenter loaderPadding"><Loading :loading="loading" /></div>
          <div v-if="!loading">
            <h2>Viestiketjut</h2>
            <div v-if="topics.length === 0">
              <p>Ei viestiketjuja</p>
            </div>

            <div v-if="topics.length > 0">
              <div class="" v-for="(topic, index) in topics" :key="index">
                <router-link :to="{ name: 'MessageBoardMessageList', params: { topicId: topic.id, messageBoardId: messageBoardId } }">
                  {{ topic.header }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <div v-if="loading" class="flexCenter loaderPadding"><Loading :loading="loading" /></div>

      <div v-if="!loading">
        <div style="color: black"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import Loading from "Loading";
import { Store } from "@moods/taitaen-core";
import { useRoute } from "vue-router";

export default {
  name: "MessageBoard",
  components: {
    Loading,
  },
  setup() {
    const route = useRoute();

    const loading = ref(false);
    const topics = ref([]);

    const messageBoardId = route.params.messageBoardId;

    onBeforeMount(() => {
      let messageBoardId = route.params.messageboardId;
      getTopics(messageBoardId);
    });

    function getTopics(boardId) {
      Store.dispatch("MessageBoards/getTopics", boardId).then(
        (s) => {
          topics.value = s.data;
          loading.value = false;
        },
        (e) => {
          console.error("Something failed: ", e);
          loading.value = false;
        }
      );
    }

    return {
      loading,
      topics,
      messageBoardId,
    };
  },
};
</script>