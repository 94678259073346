import { StructureTypes, TaskTypes } from '@utils/Training'
import demoData from '@src/demoData';

// Task key => url slug
const tasks = {
  MULTISELECT: 'multiselect',
  SINGLESELECT: 'singleselect',
  ORDER_THE_LIST: 'order-the-list',
  VIDEO: 'video',
  DRESS_UP_THE_CHARACTER: 'dress-up-the-character',
  DIAL_THE_NUMBER: 'dial-the-number',
  ORDER_THE_LETTERS: 'order-the-letters',
  HANGMAN: 'hangman',
  PANORAMA: 'panorama',
  COMBINE: 'combine'
}

const taskListItems = {
  [tasks.MULTISELECT]: {
    type: TaskTypes.MULTISELECT,
    configuration: {
      themeColor: 'rgb(89, 185, 107)',
    },
    header: 'Monivalinta',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>',
  },
  [tasks.SINGLESELECT]: {
    type: TaskTypes.SINGLESELECT,
    configuration: {
      themeColor: 'rgb(84, 65, 111)',
    },
    header: 'Valinta',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>',
  },
  [tasks.ORDER_THE_LIST]: {
    type: TaskTypes.ORDER_THE_LIST,
    configuration: {
      themeColor: 'rgb(209, 160, 23)',
    },
    header: 'Listan järjestys',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>',
  },
  [tasks.VIDEO]: {
    type: TaskTypes.VIDEO,
    configuration: {
      themeColor: 'rgb(215, 133, 215)',
    },
    header: 'Video',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>',
  },
  [tasks.DRESS_UP_THE_CHARACTER]: {
    type: TaskTypes.DRESS_UP_THE_CHARACTER,
    configuration: {
      themeColor: 'rgb(164, 36, 36)',
    },
    header: 'Hahmon pukeminen',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>'
  },
  [tasks.DIAL_THE_NUMBER]: {
    type: TaskTypes.DIAL_THE_NUMBER,
    configuration: {
      themeColor: 'rgb(120, 120, 120)',
    },
    header: 'Numeron syöttö',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>'
  },
  [tasks.ORDER_THE_LETTERS]: {
    type: TaskTypes.ORDER_THE_LETTERS,
    configuration: {
      themeColor: 'rgb(9, 2, 144)',
    },
    header: 'Sanapuzzle',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>'
  },
  [tasks.HANGMAN]: {
    type: TaskTypes.HANGMAN,
    configuration: {
      themeColor: 'rgb(50, 168, 160)',
    },
    header: 'Sana-arvaus',
    content: '<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque accumsan ex ac consequat suscipit. Morbi luctus, urna id pharetra placerat, risus urna tincidunt ante, ut bibendum diam quam sit amet metus.</p>'
  },
  [tasks.PANORAMA]: {
    type: TaskTypes.ADVANCED,
    configuration: {

    },
    header: 'Panoraama',
    content: ''
  },
  [tasks.COMBINE]: {
    type: TaskTypes.COMBINE,
    configuration: {

    },
    header: 'Yhdistäminen',
    content: ''
  }
};

const taskCourseStructures = {
  [tasks.MULTISELECT]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.MULTISELECT,
      content: {
        header: 'Valitse yksi tai useampi oikea vaihtoehto',
        htmlContent: '<p>Huomatessasi, että ihminen ei reagoi ravisteluusi, mitä teet?</p>',
      },
      data: { "options": [{ "id": 1, "values": [{ "content": "Paloturvallisuudesta vastaa toimipaikan turvallisuuspäällikkö", "value": 1 }, { "content": "Turvallisuus on kaikkien työntekijöiden vastuulla", "value": 2 }, { "content": "Alkusammutus onnistuu varmasti, kunhan sen aloittaa 5 min sisällä tulipalon alkamisesta", "value": 3 }, { "content": "Hätätilanteessa vihreällä kyltillä merkityistä ovista on aina päästävä kulkemaan ilman avainta", "value": 4 }, { "content": "Tupakointi on sallittu vain kiinteistön ulkoalueella ja ulkovarastoissa", "value": 5 }] }] },
    }
  },
  [tasks.SINGLESELECT]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.SINGLESELECT,
      content: {
        header: 'Valitse oikea vaihtoehto',
        htmlContent: '<p><strong>Minkä palon sammutat opasteessa kuvatulla alkusammutusvälineellä?</strong></p>',
      },
      data: { "options": [{ "id": 1, "content": "<img src='https://cdn.taitaen.fi/demo/paloturvallisuus/1.jpg'><br><br>", "values": [{ "content": "Vaatteet", "value": 1 }, { "content": "Sähkölaite", "value": 2 }] }, { "id": 2, "content": "<br><br><img src='https://cdn.taitaen.fi/demo/paloturvallisuus/2.jpg'><br><br>", "values": [{ "content": "Öljy tai rasva", "value": 1 }, { "content": "Puiset kalusteet", "value": 2 }] }, { "id": 3, "content": "<br><br><img src='https://cdn.taitaen.fi/demo/paloturvallisuus/3.jpg'><br><br>", "values": [{ "content": "Vaatteet", "value": 1 }, { "content": "Palonarka neste, esim. bensiini", "value": 2 }] }] },
    }
  },
  [tasks.ORDER_THE_LIST]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.ORDER_THE_LIST,
      content: {
        header: 'Vedä laatikot oikeaan järjestykseen',
        htmlContent: '<p><strong>Missä järjestyksessä tulee toimia, kun havaitaan tulipalotilanne?</strong></p>',
      },
      data: { "options": [{ "id": 1, "values": [{ "content": "Pelasta vaarassa olevat ja estä lisäonnettomuudet", "value": 1 }, { "content": "Tee hätäilmoitus", "value": 2 }, { "content": "Anna tarvittava ensiapu", "value": 3 }, { "content": "Tee tilannearvio", "value": 4 }, { "content": "Opasta sairaskuljetus perille", "value": 5 }] }] },
    }
  },
  [tasks.VIDEO]: {
    id: 'test',
    structureType: StructureTypes.ADVANCED,
    structureTypeId: 'test',
    structureRefData: {
      content: {
        header: 'Katso video ja vastaa annettuihin kysymyksiin',
        //htmlContent: 'Katso video',
      },
      data: {
        videoType: 'youtube',
        videoId: 'Mryj0JR7h54',
        videoData: [
          {
            stopTimeSeconds: 7,
            courseStructureId: '347643-234234',
          },
          {
            stopTimeSeconds: 25,
            courseStructureId: 'sdfweye-56756',
          },
          {
            stopTimeSeconds: 30,
            courseStructureId: 'fghtryrt-hlkhliuu',
          }
        ],
        courseStructures: [
          {
            id: '347643-234234',
            structureType: StructureTypes.TASK,
            structureTypeId: '347643-234234',
            structureRefData: {
              type: TaskTypes.SINGLESELECT,
              content: {
                htmlContent: '<p>Huomatessasi, että ihminen ei reagoi ravisteluusi, mitä teet?</p>',
              },
              data: '{"options": [{"id":1,"values":[{"content":"Juokse hakemaan apua","value":1},{"content":"Soita 112","value":2},{"content":"Tarkista pulssi","value":3}]}]}',
              answers: [{ "id": 1, "value": 2 }]
            }
          },
          {
            id: 'sdfweye-56756',
            structureType: StructureTypes.TASK,
            structureTypeId: 'sdfweye-56756',
            structureRefData: {
              type: TaskTypes.SINGLESELECT,
              content: {
                htmlContent: '<p>Minkä asian tarkistat ensimmäiseksi?</p>',
              },
              data: '{"options":[{"id":1,"values":[{"content":"Hengityksen","value":1},{"content":"Mahdolliset ruhjeet","value":2},{"content":"Mahdollisen verenvuodon","value":3}]}]}',
              answers: [{ "id": 1, "value": 1 }]
            }
          },
          {
            id: 'fghtryrt-hlkhliuu',
            structureType: StructureTypes.TASK,
            structureTypeId: 'fghtryrt-hlkhliuu',
            structureRefData: {
              type: TaskTypes.SINGLESELECT,
              content: {
                htmlContent: '<p>Mihin asentoon laitat tajuttoman?</p>',
              },
              data: '{"options": [{"id":1,"values":[{"content":"Selinmakuulle","value":1},{"content":"Istumaan","value":2},{"content":"Kylkiasentoon","value":3}]}]}',
              answers: [{ "id": 1, "value": 2 }]
            }
          },
        ],
      },
    },
  },
  [tasks.DRESS_UP_THE_CHARACTER]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.DRESS_UP_THE_CHARACTER,
      content: {
        header: 'Valitse oikeat suojavarusteet',
      },
      data: { "options": [{ "id": 1, "mainImageSrc": demoData.url+"/henkilonsuojaimet/ukko.png", "values": [{ "name": "Kuulosuojaimet", "src": demoData.url+"/henkilonsuojaimet/kuulosuojaimet.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/kuulosuojaimet.png", "zIndex": 1, "isDressed": false, "value": 1 }, { "name": "Huppari", "src": demoData.url+"/henkilonsuojaimet/huppari.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/huppari_paalla.png", "zIndex": 1, "excludeParts": ["body"], "isDressed": false, "value": 2 }, { "name": "Nappikuulokkeet", "src": demoData.url+"/henkilonsuojaimet/nappikuulokkeet.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/nappikuulokkeet.png", "zIndex": 0, "isDressed": false, "value": 3 }, { "name": "Ty\u00f6haalarit", "src": demoData.url+"/henkilonsuojaimet/tyohaalarit.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/tyohaalarit.png", "zIndex": 1, "excludeParts": ["body", "trousers"], "isDressed": false, "value": 4 }, { "name": "Suojalasit", "src": demoData.url+"/henkilonsuojaimet/suojalasit.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/suojalasit_paassa.png", "zIndex": 2, "isDressed": false, "value": 5 }, { "name": "Turvakeng\u00e4t", "src": demoData.url+"/henkilonsuojaimet/turvakengat.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/turvakengat.png", "zIndex": 0, "excludeParts": ["shoes"], "isDressed": false, "value": 6 }, { "name": "Sandaalit", "src": demoData.url+"/henkilonsuojaimet/sandaalit.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/sandaalit.png", "zIndex": 0, "excludeParts": ["shoes"], "isDressed": false, "value": 7 }, { "name": "Farkut", "src": demoData.url+"/henkilonsuojaimet/farkut.png", "dressedSrc": demoData.url+"/henkilonsuojaimet/originaalit/farkut.png", "zIndex": 1, "excludeParts": ["trousers"], "isDressed": false, "value": 8 }] }] },
    }
  },
  [tasks.DIAL_THE_NUMBER]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.DIAL_THE_NUMBER,
      content: {
        header: 'Mikä on yleinen hätänumero?',
      },
      data: { "options": ['112'] },
    }
  },
  [tasks.ORDER_THE_LETTERS]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.ORDER_THE_LETTERS,
      content: {
        header: 'Järjestele kirjaimet oikeaan järjestykseen',
      },
      data: { "options": ['T', 'A', 'I', 'T', 'A', 'E', 'N'] },
    }
  },
  [tasks.HANGMAN]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.HANGMAN,
      content: {
        header: 'Arvaa sana',
      },
      data: { "options": ['Taitaen'] },
    }
  },
  [tasks.PANORAMA]: {
    id: 'test',
    structureType: StructureTypes.ADVANCED,
    structureTypeId: 'test',
    structureRefData: {
      content: {
        //header: 'Tee panoraamassa olevat tehtävät',
        header: 'Tutustu varastohalliin panoraaman avulla',
        htmlContent: '<p><strong>Klikkaamalla nuolia saat lisätietoa.</strong></p>',
      },
      data: {
        containerType: 'panorama',
        xmlstring: '<krpano><view hlookat="0" vlookat="0" fovtype="MFOV" fov="120" fovmin="20" fovmax="120" /><image><sphere url="/360/sphere.jpg" /></image></krpano>',
        globalHotspotSettings: {
          showContentAfterMs: 1000,
          looktohotspot: { // hotspotname*, fov*, motiontype*, shortestway*)
            fov: 40,
          }
        },
        hotspots: [
          {
            hotspot: {
              // "hl": -321.8036592781239,
              // "vl": 18.62646436419477,
              // "fov": 18.7893536366189,
              // "distortion": 0
              "hl": -322.8036592781239,
              "vl": 37.62646436419477,
              "fov": 18.7893536366189,
              "distortion": 0
            },
            courseStructureId: '347643-234234',
          },
          {
            hotspot: {
              "hl": -41.447285919061954,
              "vl": 22.772146549221016,
              "fov": 30.544523265780285,
              "distortion": 0
            },
            courseStructureId: 'sdfweye-56756',
          },
          {
            hotspot: {
              "hl": -109.65169795757829,
              "vl": 2.9670034157596725,
              "fov": 90,
              "distortion": 0
            },
            courseStructureId: 'tutyjghjgh-5664534554yrt-rthtrhrt',
          },
          {
            hotspot: {
              "hl": -219.00379206203507,
              "vl": 11.11394045434331,
              "fov": 44.322973826507415,
              "distortion": 0
            },
            courseStructureId: 'fghtryrt-hlkhliuu',
          }
        ],
        courseStructures: [
          {
            id: '347643-234234',
            structureType: StructureTypes.CONTENT,
            structureTypeId: '347643-234234',
            structureRefData: {
              htmlContent: '<p><strong>Huomioitavaa</strong></p><p>Muista pitää varastohallin käytävillä kulku vapaana.</p>',
            }
            // structureType: StructureTypes.TASK,
            // structureTypeId: '347643-234234',
            // structureRefData: {
            //     type: TaskTypes.SINGLESELECT,
            //     content: {
            //         htmlContent: '<p>Huomatessasi, että ihminen ei reagoi ravisteluusi, mitä teet?</p>',
            //     },
            //     data: '[{"id":1,"values":[{"content":"Juokse hakemaan apua","value":1},{"content":"Soita 112","value":2},{"content":"Tarkista pulssi","value":3}]}]',
            //     answers: [{"id": 1, "value": 2}]
            // }
          },
          {
            id: 'sdfweye-56756',
            structureType: StructureTypes.TASK,
            structureTypeId: 'sdfweye-56756',
            structureRefData: {
              type: TaskTypes.MULTISELECT,
              content: {
                htmlContent: '<p><strong>Valitse onko väittämä oikein vai väärin.</strong></p>',
              },
              data: { "options": [{ "id": 1, "values": [{ "content": "Trukin ajamiseen riittää esimiehen lupa.", "value": 1 }, { "content": "Kuorma voi olla ylhäällä ajon aikana.", "value": 2 }, { "content": "Pidä trukin haarukat kuljetuksen aikana noin 80 cm:n korkeudella.", "value": 3 }, { "content": "Trukin haarukoiden laahaaminen maata vasten on kielletty.", "value": 4 }] }] },
              answers: [{ "id": 1, "value": 4 }]
            }
          },
          {
            id: 'tutyjghjgh-5664534554yrt-rthtrhrt',
            structureType: StructureTypes.CONTENT,
            structureTypeId: 'tutyjghjgh-5664534554yrt-rthtrhrt',
            structureRefData: {
              htmlContent: '<p><strong>Lorem ipsum dolor</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dui mauris, accumsan condimentum diam a, efficitur tincidunt ante. Pellentesque ac vestibulum justo.</p><p>Vestibulum pellentesque nisl ut sapien dapibus, nec maximus tortor auctor. Suspendisse vel imperdiet nunc, a iaculis justo. Donec ac elementum est. In placerat accumsan nunc, et euismod velit suscipit semper. Fusce elementum sapien id magna consequat, et viverra mi viverra. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Pellentesque at elit non ex mattis luctus sed et eros. Quisque quis tortor ut nunc hendrerit porta. Sed non felis ligula. Maecenas et nibh at velit rutrum imperdiet. Proin dolor lectus, posuere imperdiet dapibus quis, pellentesque sit amet enim.</p>',
            }
          },
          {
            id: 'fghtryrt-hlkhliuu',
            structureType: StructureTypes.CONTENT,
            structureTypeId: 'fghtryrt-hlkhliuu',
            structureRefData: {
              htmlContent: '<p><strong>Lorem ipsum dolor</strong></p><p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc pretium congue sapien ac volutpat. Quisque egestas feugiat lectus, in eleifend libero gravida non. Donec posuere placerat ligula, vestibulum consequat mi ullamcorper at. Quisque pellentesque, ligula vulputate ultricies consectetur, nibh dui rutrum justo, vel commodo lacus nulla non lacus.</p><p>Aliquam porttitor erat ut massa dictum accumsan. Interdum et malesuada fames ac ante ipsum primis in faucibus. Phasellus ullamcorper eros nec neque viverra dignissim. Nullam dapibus nulla orci, sit amet placerat neque ultrices eget. In sit amet dapibus ligula. Proin vel eros vitae odio lacinia pulvinar sit amet non ante. Nullam tincidunt elementum libero at porta. </p>',
            }

            // structureType: StructureTypes.TASK,
            // structureTypeId: 'fghtryrt-hlkhliuu',
            // structureRefData: {
            //     type: TaskTypes.SINGLESELECT,
            //     content: {
            //         htmlContent: '<p>Mihin asentoon laitat tajuttoman?</p>',
            //     },
            //     data: '[{"id":1,"values":[{"content":"Selinmakuulle","value":1},{"content":"Istumaan","value":2},{"content":"Kylkiasentoon","value":3}]}]',
            //     answers: [{"id": 1, "value": 2}]
            // }
          }
        ],
      }
    }
  },
  [tasks.COMBINE]: {
    id: 'test',
    structureType: StructureTypes.TASK,
    structureTypeId: 'test',
    structureRefData: {
      id: 'test',
      type: TaskTypes.COMBINE,
      content: {
        header: 'Valitse oikea vaihtoehto',
        htmlContent: '<p><strong>Minkä palon sammutat opasteessa kuvatulla alkusammutusvälineellä?</strong></p>',
      },
      data: {
        "options": [
          {
            "id": 1,
            "content": "Testikuvat",
            "clone": true,
            "values": [
              {
                "id": 1,
                "data": {
                  "url": "/testikuva1.png",
                  "inputText": ""
                }
              },
              {
                "id": 2,
                "data": {
                  "url": "/testikuva2.png",
                  "inputText": ""
                }
              },
              {
                "id": 3,
                "data": {
                  "url": "/testikuva3.png",
                  "inputText": ""
                }
              }
            ]
          },
          {
            "id": 2,
            "content": "Testi 1",
            "values": []
          },
          {
            "id": 3,
            "content": "Testi 2",
            "values": []
          },
          {
            "id": 4,
            "content": "Testi 3",
            "values": []
          },
          {
            "id": 5,
            "content": "Testi 4",
            "values": []
          },
        ],
        "settings": {
          "disableDuplicate": true,
        },
      },
    }
  },
}

export default {
  tasks,
  taskListItems,
  taskCourseStructures
}