<template>
  <div class="containerVideo">
    <div ref="fullScreenRef" class="fullScreen" :style="[videoState.hideCursor ? 'cursor: none' : '']" @mousemove="onMouseMove" @click="onClick">
      <template v-if="showModal">
        <Modal>
          <template v-slot:header></template>
          <template v-slot:body>
            <div ref="modalBodyRef">
              <template v-if="showVideoTaskResult">
                <h1>Tulos</h1>
                <p>Tehtävä hyväksytty, hieno suoritus!</p>
              </template>
              <template v-else-if="childCourseStructure">
                <HandleCourseStructure :isInsideContainer="true" :courseStructure="childCourseStructure" />
              </template>
              <button class="button raquoAfter" @click="check">Jatka</button>
            </div>
          </template>
        </Modal>
      </template>

      <div class="makeFullscreen">
        <VideoHandler :id="courseStructure.id" :videoType="data.videoType" :videoId="data.videoId" />
      </div>
    </div>
  </div>
</template>

<script>
// Other
import { ref, shallowRef, watch, computed, defineAsyncComponent } from "vue";

// Uses
import useVideoState from "../task-types/useVideoState";
import { useFullscreen } from "../task-types/useFullscreen";

// Components
import HandleCourseStructure from "../HandleCourseStructure";
import Modal from "@tools/Modal";
import VideoHandler from "../task-types/VideoHandler";

// Functions
import { CourseStructureHelper } from "@moods/taitaen-core";
//import { isTask, getCourseStructureById } from '@helpers/CourseStructureHelper'
import { VideoPlayerState } from "@utils/Video";

import useContainer from "./useContainer";

export default {
  name: "VideoContainer",
  components: {
    Modal,
    VideoHandler,
    //HandleCourseStructure,
    HandleCourseStructure: defineAsyncComponent(() => Promise.resolve(HandleCourseStructure)),
  },
  props: ["courseStructure", "data", "isTest"],
  setup(props) {
    // Container startup
    const { getChildrenCourseStructures, initAnswers } = useContainer();
    const childrenCourseStructures = getChildrenCourseStructures(props);
    initAnswers(childrenCourseStructures);
    // Container startup ends

    const showModal = ref(false);
    const showVideoTaskResult = ref(false);
    const fullScreenRef = ref(null);
    const modalBodyRef = ref(null);

    //const { videoType, videoId, videoOptions, videoData, courseStructures } = VideoData();

    const { resetState, videoState } = useVideoState();
    resetState();

    const { setFullscreenRef, isFullscreenOn, toggleFullscreen, openFullscreen, closeFullscreen } = useFullscreen();
    setFullscreenRef(fullScreenRef);
    videoState.isFullscreenOn = shallowRef(isFullscreenOn);
    videoState.toggleFullscreen = toggleFullscreen;
    videoState.openFullscreen = openFullscreen;
    videoState.closeFullscreen = closeFullscreen;

    const childCourseStructure = ref(null);

    function check() {
      if (showVideoTaskResult.value) {
        continueVideo();
        return;
      }
      // if (CourseStructureHelper.isTask(childCourseStructure.value)) {
      // }

      continueVideo();
    }

    function continueVideo() {
      showModal.value = false;
      videoState.play = true;
    }

    var timeout;
    function onMouseMove() {
      videoState.hideVideoToolbar = false;
      videoState.hideCursor = false;
      clearTimeout(timeout);
      timeout = setTimeout(function () {
        if (videoState.play) {
          videoState.hideVideoToolbar = true;
          //fullScreenRef.value.style.cursor = 'none';
          videoState.hideCursor = true;
        }
      }, 3000);
    }

    function onClick() {
      videoState.hideVideoToolbar = false;
      videoState.hideCursor = false;
    }

    // Show modal if seconds match to courseStructure
    var currentIndex = 0;
    watch(
      () => videoState.progressTimeSeconds,
      () => {
        if (props.data.videoData[currentIndex]) {
          if (videoState.progressTimeSeconds >= props.data.videoData[currentIndex].stopTimeSeconds) {
            showCourseStructure(props.data.videoData[currentIndex].courseStructureId);
            currentIndex++;
          }
        }
      }
    );

    // Open fullscreen when video started and close when finished
    watch(
      () => videoState.state,
      () => {
        if (videoState.state === VideoPlayerState.STARTED) {
          openFullscreen();
        } else if (videoState.state === VideoPlayerState.ENDED) {
          closeFullscreen();
        }
      }
    );

    function showCourseStructure(id) {
      let cs = null;
      if (props.courseStructure.children) {
        cs = CourseStructureHelper.getCourseStructureById(props.courseStructure.children, id);
      } else if (props.isTest) {
        cs = CourseStructureHelper.getCourseStructureById(props.data.courseStructures, id);
      }
      if (cs) {
        videoState.play = false;
        childCourseStructure.value = cs;
        showModal.value = true;
      }
    }

    return {
      showModal: computed(() => showModal.value),
      fullScreenRef,
      childCourseStructure: computed(() => childCourseStructure.value),
      check,
      modalBodyRef,

      onMouseMove,
      onClick,

      videoState,

      showVideoTaskResult: computed(() => showVideoTaskResult.value),
    };
  },
};
</script>