<template>
  <div class="col-md-6 col-lg-8 col-xl-9 box">
    <div class="defaultBgColor trainingBg contentBox" :style="{ backgroundImage: getMainImageUrl() }">
      <div class="row" style="height: 100%">
        <div class="col-lg-12">
          <div class="itemHeader">
            <div class="details">
              <div>
                <h1>{{ training.name }}</h1>
                <div v-html="getIngress()"></div>
                <!-- <div v-html="training.contents.ingress.htmlContent"></div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getJson, getConfContentValue } from "@utils/General";

export default {
  name: "TrainingInfoBox",
  props: ["training"],
  setup(props) {
    const confContent = getJson(props.training.confContent);

    function getMainImageUrl() {
      const res = getConfContentValue(confContent, "mainImage");
      if (res) {
        return "url('" + res + "')";
      }
      return null;
    }

    function getIngress() {
      return getConfContentValue(confContent, "ingress");
    }

    return {
      getMainImageUrl,
      getIngress,
    };
  },
};
</script>

<style lang="scss" scoped>
.trainingBg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 65% center;
  height: 100%;
}
.itemHeader {
  display: flex;
  align-items: center;
  height: 100%;

  padding-top: 15px;
  padding-bottom: 15px;

  h1 {
    margin-bottom: 0;
  }

  .details {
    max-width: 600px;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 20px;
  }
}
</style>