<template>
  <div class="demoMode" :class="{ 'active': isToggled === true }">
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cone-striped" viewBox="0 0 16 16">
      <path d="m9.97 4.88.953 3.811C10.159 8.878 9.14 9 8 9c-1.14 0-2.158-.122-2.923-.309L6.03 4.88C6.635 4.957 7.3 5 8 5s1.365-.043 1.97-.12zm-.245-.978L8.97.88C8.718-.13 7.282-.13 7.03.88L6.275 3.9C6.8 3.965 7.382 4 8 4c.618 0 1.2-.036 1.725-.098zm4.396 8.613a.5.5 0 0 1 .037.96l-6 2a.5.5 0 0 1-.316 0l-6-2a.5.5 0 0 1 .037-.96l2.391-.598.565-2.257c.862.212 1.964.339 3.165.339s2.303-.127 3.165-.339l.565 2.257 2.391.598z"/>
    </svg> -->
    <template v-if="isTestModeAllowed">
      <label class="switch">
        <input type="checkbox" v-model="isToggled" @change="toggle">
        <span class="slider round"></span>
      </label>
      <span>Testaustila</span>
    </template>
    <template v-else-if="isToggled">
      Testaustilassa
    </template>
  </div>
</template>

<script>
import { ref } from 'vue';
import { UserApi } from '@moods/taitaen-core';

export default {
  setup() {
    const isToggled = ref(UserApi.isTestModeEnabled());

    function toggle() {
      if (!UserApi.isTestModeAllowed()) {
        isToggled.value = UserApi.isTestModeEnabled();
        return;
      }

      if (isToggled.value) {
        UserApi.enableTestMode()
        .catch(() => {})
      } else {
        UserApi.disableTestMode()
        .catch(() => {})
      }
    }

    return {
      isTestModeAllowed: UserApi.isTestModeAllowed(),
      isToggled,
      toggle
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/_taitaen-variables.scss";

.demoMode {
  padding-top: 30px;
  max-width: 1000px;
  margin: auto;

  display: flex;
  align-items: center;

  svg {
    color: var(--theme-color-third);
  }

  &:not(.active) {
    color: #ccc;
  }

  span {
    margin-right: 5px;

    -webkit-transition: color .4s;
    transition: color .4s;
  }
}

 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--theme-color-third);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--theme-color-third);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>