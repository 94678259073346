<template>
  <div :class="isRootMessage ? 'message' : 'reply'">
    <p>
      <small>Kirjoitettu: {{ FormattedDateTime(message.createdAt) }}</small>
      <br />{{ message.header }}
    </p>

    <div v-html="message.content"></div>

    <div v-if="message.parentId == null">
      <a style="color: blue" @click="showReplyFormButton">Vastaa</a>
      <div v-if="showReplyFormFlag">
        <ReplyForm :topicId="message.messageBoardTopicId" :reloadMessages="reloadMessages" :messageId="message.id" />
      </div>
    </div>
  </div>

  <div v-for="(childMessage, index) in message.children" :key="index">
    <Message :message="childMessage" />
  </div>
</template>

<script>
import { ref } from "vue";
import { FormattedDateTime } from "@utils/DateTools";
import ReplyForm from "./ReplyForm";

export default {
  name: "Message",
  props: ["message", "reloadMessages"],
  components: {
    ReplyForm,
  },

  setup(props) {
    const loading = ref(false);
    const showReplyFormFlag = ref(false);
    const isRootMessage = ref(props.message.parentId == null);

    // onBeforeMount(() => {

    // });

    function showReplyFormButton() {
      showReplyFormFlag.value = !showReplyFormFlag.value;
    }

    return {
      loading,
      showReplyFormButton,
      showReplyFormFlag,
      isRootMessage,
      FormattedDateTime,
    };
  },
};
</script>

<style scoped>
.message {
  padding: 1rem;
  background-color: #f0b100;
}

.reply {
  padding: 1rem;
  background-color: #be8c00;
  margin-left: 2rem;
}
</style>