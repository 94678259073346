<template>
  <div class="col-md-6 col-lg-4 col-xl-3 box">
    <div class="course courseDefaultBgColor">
      <div class="courseImg" :style="{ backgroundImage: getMainImageUrl() }">
        <div class="absCenteredText"></div>
      </div>
      <div class="courseContent boxPadding">
        <div>
          <ProgressList :course="course" :courseState="trainingState.courseStates.find(({ courseId }) => courseId === course.id)" :fillByPercentages="true" :showLabel="true" />
        </div>
        <div class="courseHeader">
          <div>
            <h3>{{ course.name }}</h3>
          </div>
        </div>
        <template v-if="course.contents && course.contents.ingress">
          <div v-html="course.contents.ingress.htmlContent"></div>
        </template>
        <template v-else>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus tincidunt, augue id convallis suscipit, nisi massa dictum elit, nec volutpat turpis diam non dui. Nam sed nisl sit amet justo maximus cursus. Quisque consectetur nisl neque, et congue purus tristique sed. Donec dapibus felis ac ante sodales, at congue justo blandit. Cras rutrum neque ut commodo vehicula.</p>
        </template>

        <div class="bottomContainer">
          <router-link v-if="course.courseStructure.length > 0" :to="{ name: 'TrainingExecution', params: { trainingId: training.id, courseId: course.id } }" class="button">Koulutukseen</router-link>
          <button v-else class="button" disabled>Koulutukseen</button>
          <div class="courseIcon" v-if="course.configuration.icon && course.configuration.icon.length > 0">
            <img :src="course.configuration.icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProgressList from "@tools/ProgressList";
import { getJson, getConfContentValue } from "@utils/General";

export default {
  name: "CourseBox",
  components: {
    ProgressList,
  },
  props: ["course", "trainingState", "training"],
  setup(props) {
    const confContent = getJson(props.course.confContent);

    function getBackground(config) {
      if (config) {
        return config.themeGradient ? config.themeGradient : config.themeColor;
      }
      return "secondColor";
    }

    function getMainImageUrl() {
      const res = getConfContentValue(confContent, "mainImage");
      if (res) {
        return "url('" + res + "')";
      }
      return null;
    }

    return {
      getBackground,
      getMainImageUrl,
    };
  },
};
</script>

<style lang="scss" scoped>
.course {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
}

.courseDefaultBgColor {
  background-color: var(--theme-color-fourth);
}
.courseContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
.courseImg {
  position: relative;
  background-color: var(--theme-color-sixth);
  padding-bottom: 60%;

  // background-image: url('/public/swegon/30544860643_14c7690f8f_o1.jpg');
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.courseIcon {
  width: 4rem;
  height: 4rem;
  float: right;
  margin-left: 15px;
  //transform: translateY(-1rem);
}
.award {
  color: gold;
  font-size: 40px;
}

// .button.disabledButton:hover {
//   cursor: default;
//   color: initial;
// }

.courseContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.courseHeader {
  display: flex;
  justify-content: space-between;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 15px;
}

// .boxPadding {
//   padding: 30px 20px;
// }

.bottomContainer {
  align-items: center;
  justify-content: space-between;
}
</style>