<template>
  <div>
    <div v-if="loading" class="flexCenter loaderPadding"><Loading :loading="loading" /></div>
    <form v-if="!loading">
      <textarea placeholder="Viesti tähän" ref="textarea"></textarea>
      <br />
      <button @click="sendReply">Lähetä</button>
    </form>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import Loading from "Loading";
import { Store } from "@moods/taitaen-core";

export default {
  name: "ReplyForm",
  props: ["topicId", "messageId", "reloadMessages"],

  components: {
    Loading,
  },

  setup(props) {
    const textarea = ref(null);
    const loading = ref(false);
    onBeforeMount(() => {});

    function send(content) {
      loading.value = true;
      let data = {
        TopicId: props.topicId,
        MessageId: props.messageId,
        Header: "",
        Content: content,
      };
      Store.dispatch("MessageBoards/postMessage", data).then(
        () => {
          props.reloadMessages();
          loading.value = false;
        },
        (e) => {
          console.error("Something failed: ", e);
          loading.value = false;
        }
      );
    }

    function sendReply(e) {
      e.preventDefault();
      send(textarea.value.value);
    }

    return {
      loading,
      sendReply,
      textarea,
    };
  },
};
</script>