<template>
  <div>
    <div class="iframeContainer">
      <div ref="iframeOverlay" class="videoWrapper" @click="overlayClick"></div>
      <div :id="'video' + id"></div>
    </div>
    <slot name="toolbar"></slot>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import InjectYoutubeApi from "@utils/InjectYoutubeApi";

export default {
  name: "VideoYoutubeSimple",
  props: ["id", "videoId", "options", "play"],
  setup(props, { emit }) {
    if (!props.videoId) {
      alert("Aseta videoID");
    }

    watch(
      () => props.play,
      () => {
        if (props.play) {
          player.playVideo();
        } else {
          player.pauseVideo();
        }
      }
    );

    var defaultOptions = {
      controls: 0, // Show controls or not
      playsinline: 1, // Play inline or fullscreen in an HTML5 player on iOS: 0 = causes fullscreen, 1 = inline
      rel: 0, // Show related videos or not (changed after September 25, 2018)
    };
    var options = {};

    const iframeOverlay = ref(null);
    //var YT = null;

    const timeupdater = ref(null);
    const videotime = ref(0);
    emit("videotime", videotime);
    var player = null;

    onMounted(() => {
      setOptions();

      // This code loads the IFrame Player API code asynchronously.
      InjectYoutubeApi.check().then(() => {
        initPlayer();
      });
    });

    function setOptions() {
      if (props.options) {
        options = props.options;
      } else {
        options = defaultOptions;
      }
    }

    function initPlayer() {
      player = new window.YT.Player("video" + props.id, {
        height: "390",
        width: "640",
        videoId: props.videoId,
        playerVars: options,
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });
      emit("player", player);
    }

    function onPlayerReady() {
      emit("on-player-ready");
    }

    // The API calls this function when the player's state changes. The function indicates that when playing a video (state=1), the player should play for six seconds and then stop.
    function onPlayerStateChange(event) {
      emit("state-changed", event.data, window.YT.PlayerState);

      if (event.data === window.YT.PlayerState.PLAYING) {
        timeupdater.value = setInterval(updateTime, 100);
      }
    }

    function updateTime() {
      if (player && player.getCurrentTime) {
        videotime.value = player.getCurrentTime();
      }
    }

    function overlayClick() {
      if (!player) {
        return;
      }

      var state = player.getPlayerState();

      if (state === window.YT.PlayerState.PLAYING) {
        player.pauseVideo();
      } else {
        player.playVideo();
      }
    }

    return {
      iframeOverlay,
      overlayClick,
    };
  },
};
</script>
