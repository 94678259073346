<template>
  <div class="container">
    <div class="row">
      <div class="col mt-5 mb-5">
        <div>
          <h1>Omat tiedot</h1>
          <br>
          <h2>Suoritetut koulutukset</h2>
          <AccomplishedCourses/>
          <h2>Salasanan vaihto</h2>
          <ChangePasswordForm/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AccomplishedCourses from './AccomplishedCourses'
import ChangePasswordForm from './ChangePasswordForm'

export default {
  name: 'OwnInformation',
  components: {
    AccomplishedCourses,
    ChangePasswordForm
  },
  setup() {
  }
};
</script>

<style lang="scss">
</style>