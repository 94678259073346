<template>
  <form action="#" @submit.prevent="changePassword">
    <input id="password" type="password" name="password" placeholder="Salasana" v-model="password" />
    <br>
    <input id="newPassword1" type="password" name="newPassword1" placeholder="Uusi salasana" v-model="newPassword1" />
    <br>
    <input id="newPassword2" type="password" name="newPassword2" placeholder="Uusi salasana uudelleen" v-model="newPassword2" />
    <br>
    <input class="button" type="submit" value="Vaihda" />

    <Loading :loading="loading" />
    <template v-if="!loading"><Messages :isSuccess="isSuccess" :messages="messages" /></template>
  </form>
</template>

<script>
import { ref, computed } from 'vue';
import { Store } from "@moods/taitaen-core";
import Loading from 'Loading'
import Messages from "Messages";

export default {
  components: {
    Loading,
    Messages
  },
  setup() {
    const loading = ref(false);
    const messages = ref({});
    const isSuccess = ref(false);

    // Change password
    const password = ref("");
    const newPassword1 = ref("");
    const newPassword2 = ref("");

    function resetFormValues() {
      password.value = "";
      newPassword1.value = "";
      newPassword2.value = "";
    }

    function changePassword() {
      loading.value = true;
      messages.value = {};

      if (password.value === '' || newPassword1.value === '' || newPassword2.value === '') {
        isSuccess.value = false;
        messages.value = 'Täydennä ensin kaikki kentät';
        loading.value = false;
        return;
      }

      Store.dispatch("User/updatePassword", {
        Password: password.value,
        NewPassword1: newPassword1.value,
        NewPassword2: newPassword2.value,
      })
        .then((res) => {
          loading.value = false;
          if (res.data.errors) {
            messages.value = res.data.errors;
          } else {
            isSuccess.value = true;
            messages.value = 'Salasana vaihdettu onnistuneesti';

            resetFormValues();
          }
        })
        .catch((err) => {
          loading.value = false;
          isSuccess.value = false;
          messages.value = "Salasanan vaihto epäonnistui";
          
          if (err.response.data) {
            if (err.response.data.errors) {
              messages.value = err.response.data.errors;
            } else if (err.response.data === 'current_password_is_incorrect') {
              messages.value = 'Väärä salasana'
            }
          }
        });
    }

    return {
      changePassword,
      loading: computed(() => loading.value),

      password,
      newPassword1,
      newPassword2,
      messages,
      isSuccess
    }
  }
}
</script>