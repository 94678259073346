<template>
  <Modal class="filledModal" :closeModal="closeModal">
    <template v-slot:header></template>
    <template v-slot:body>
      <div>
        <h2>Anna palautetta</h2>
        <p>Kysy tai kommentoi mitä tahansa koulutukseen liittyvää. Voit myös antaa palautetta käyttökokemuksesta tai sisällöistä. Kehitetään koulutusta yhdessä!</p>
        <form action="#" @submit.prevent="sendFeedback">
          <label id="message">Viesti</label><br>
          <textarea id="message" v-model="message" name="message"/>
          <br><br>
          <input class="button" type="submit" value="Lähetä" />
        </form>
        <br>
        <Loading :loading="loading" />
        <template v-if="!loading"><Messages :isSuccess="formResponse.status" :messages="formResponse.message" /><br/></template>
        <button class="button raquoAfter" @click="closeModal">Sulje</button>
      </div>
    </template>
  </Modal>
</template>

<script>
import { computed, ref } from "vue";
import Modal from "@tools/Modal";
import Messages from "Messages";
import Loading from "Loading";
import useFeedbackForm from "@hooks/useFeedbackForm"
import { Store } from '@moods/taitaen-core'

export default {
  name: "FeedbackForm",
  components: {
    Modal,
    Messages,
    Loading
  },
  setup() {
    const message = ref('');
    const formResponse = ref({
      status: false,
      message: "",
    });
    const loading = ref(false);
    const { isFeedbackFormOpened } = useFeedbackForm;

    function sendFeedback() {
      if (!message.value) {
        formResponse.value.message = "Palaute on tyhjä";
        return;
      }
      formResponse.value.message = "";

      Store.dispatch("App/sendForm", {
        "TemplateId": "05119c04-ac0b-11eb-86ac-9e0cbb865ece",
        "Fields": {
          "message": message.value,
        },
      })
      .then(() => {
        message.value = "";
        formResponse.value.status = true;
        formResponse.value.message = "Palaute lähetetty, kiitos!";
      })
      .catch(() => {
        formResponse.value.status = false;
        formResponse.value.message = "Palautteen lähetys epäonnistui";
      })
    }

    function closeModal() {
      isFeedbackFormOpened.value = false;
    }

    return {
      message,
      formResponse,
      loading: computed(() => loading.value),
      sendFeedback,
      closeModal
    }
  }
}

</script>


<style lang="scss" scoped>
.filledModal {
  height: auto;
}

textarea {
  width: 100%;
  height: 300px;
}
</style>