import { reactive } from 'vue'

const initialState = {
    player: null,
    state: null,
    play: false,
    volume: 50,
    lastVolume: 0,
    hideVideoToolbar: false,
    hideCursor: false,

    isFullscreenOn: null,
    toggleFullscreen: null,
    openFullscreen: null,
    closeFullscreen: null,
    progressTimeSeconds: 0,
};

const videoState = reactive({ ...initialState });

export default function useVideoState() {
    function resetState() {
        Object.assign(videoState, initialState);
    }

    return {
        resetState,
        videoState
    }
}