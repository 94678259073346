
/// MUY Private methods!! Do not disturb!
const _getDateObject = (input) => {
  let t = typeof input;
  if (t === "string") {
    return ConvertToDateObject(input)
  }

  if (t === 'Object') {
    if (typeof input.getMonth === 'function') {
      return input;
    }
  }
  throw "Input is not a DateObject!";
}

const _getDatePart = (obj) => {

  let d = obj.getDate();
  let m = obj.getMonth() + 1;
  let y = obj.getFullYear()

  let parts = [
    d.toString().padStart(2, 0),
    m.toString().padStart(2, 0),
    y.toString(),
  ];

  return parts.join(".");
}

const _getTimePart = (obj) => {
  var h = obj.getHours();
  var m = obj.getMinutes();

  let parts = [
    h.toString().padStart(2, 0),
    m.toString().padStart(2, 0),
  ];
  return parts.join(".");
}



// Public methods.
const ConvertToDateObject = (dateInIsoFormat) => {
  let dateObject = new Date(Date.parse(dateInIsoFormat));

  if (dateObject == null || isNaN(dateObject)) {
    throw "Cannot parse date string";
  }
  return dateObject;
}

const FormattedDate = (dateVar) => {
  try {
    var d = _getDateObject(dateVar);
    return _getDatePart(d);
  } catch (err) {
    console.error(err);
    return null;
  }
}

const FormattedDateTime = (dateVar) => {
  try {
    var d = _getDateObject(dateVar);
    return _getDatePart(d) + " " + _getTimePart(d);
  } catch (err) {
    console.error(err);
    return null;
  }
}

export { FormattedDate, FormattedDateTime, ConvertToDateObject }