
import { onMounted, onBeforeUnmount, watch, isRef } from 'vue'

import { useWindowSize } from './useWindowSize'
const { getFullViewportHeightMinusHeader, resizeListener } = useWindowSize();

export function useResize() {
  var backgroundRef, content;

  /**
   * Set
   * 
   * @param HTMLElement _backgroundRef 
   * @param any _content
   * 
   * @return void
   */
  function set(_backgroundRef, _content) {
    backgroundRef = _backgroundRef;
    content = _content;

    setListeners();
  }

  function setListeners() {
    onMounted(() => {
      backgroundRef.value.style.height = getFullViewportHeightMinusHeader() + 'px';
      check()
    })

    const resizeListenerWatch = watch(() => resizeListener.isChanged, () => {
      backgroundRef.value.style.height = getFullViewportHeightMinusHeader() + 'px';
      check();
    })

    // const orientationWatch = watch(() => orientation.value, () => {
    //     backgroundRef.value.style.height = getFullViewportHeightMinusHeader()+'px';
    //     check();
    // })

    onBeforeUnmount(() => {
      // if (orientationWatch) {
      //     orientationWatch();
      // }
      if (resizeListenerWatch) {
        resizeListenerWatch();
      }
    })
  }

  function getMinHeight() {
    let minHeight = null;
    if (isRef(content)) {
      minHeight = content.value.offsetHeight;
    } else if (!isNaN(content)) {
      minHeight = content;
    }

    return minHeight;
  }

  function check() {
    let minHeight = getMinHeight();
    setHeight(backgroundRef.value, minHeight);
  }

  function setHeight(backgroundEl, minHeight) {
    //backgroundEl.style.height = '';

    if (minHeight >= backgroundEl.offsetHeight) {
      backgroundEl.style.height = minHeight + 'px'
    }
  }

  return {
    set,
  }
}