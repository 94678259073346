<template>
  <img class="swegonLeaf" :src="tenantData.url + '/swegon_leaf_pattern.png'" alt="Swegon leaf" />
</template>

<script>
import tenantData from "@src/tenantData";

export default {
  setup() {
    return {
      tenantData,
    }
  }
}
</script>

<style lang="scss" scoped>
.swegonLeaf {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 0;
}
</style>