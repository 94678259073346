import { TrainingHandler } from '@moods/taitaen-core'

const TrainingInstanceHandler = {
  trainingInstance: null,

  initTrainingInstance() {
    this.trainingInstance = new TrainingHandler();
  },
}

export default TrainingInstanceHandler;
